import {useEffect, useState} from "react";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import React from "react";
import {FormControl, FormLabel} from "react-bootstrap";
import {FormGroup} from "reactstrap";
import moment from "moment";
import {capitalize} from "./firstDayOfPeriod";

const YearMonthsBarChart = (props) => {
  let {field, periods, showpicker, profitCenter, profitCenterArea} = props;

  const translations = {
    saleSum: "Försäljning per månad",
    saleTax0Sum: "Försäljning per månad ex Moms",
    customerQty: "Antal kunder per månad"
  }

  const [state, setState] = useState({
    data: [],
    periods: periods,
    field: field,
    fieldText: translations[field],
    showpicker: showpicker
  });

  const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
  const formatter = new Intl.DateTimeFormat('sv-SE', options);
  
  const fetchData = () => {
    const formattedPeriods = periods.map(p => moment(p).format("YYYYMMDD"));
    let qs = `&profitCenterId=${profitCenter?.code ?? ""}&${formattedPeriods.map(fp => `&period=${fp}`).join('')}`;
    if (profitCenter == null && profitCenterArea != null) {
      qs = `&areaId=${profitCenterArea?.code ?? ""}&${formattedPeriods.map(fp => `&period=${fp}`).join('')}`;
    }

    fetch(`/api/sales?periodType=Month${qs}`)
        .then(response => response.json())
        .then((data) => {
          data.sales.sort((a, b) => a.periodId - b.periodId);
          const fixed = data.sales.map(p => {
            let parts = formatter.formatToParts(moment(p.periodId,'YYYYMMDD').toDate());
            const month = parts.find(r => r.type === 'month').value;
            
            let item = { name: capitalize(month), date : moment(p.periodId.toString(),'YYYYMMDD').toDate() };
            item[state.fieldText] = p[state.field];

            return item;
          });
          setState({...state, data: fixed});
        });
  }

  useEffect(() => {
    fetchData()
  }, [props, state.period]);


  const onPeriodChange = (event) => {
    let value = event.target.value
    setState({
      ...state,
      data: state.data,
      period: value
    });
  }

  let picker;
  if (props.showpicker) {
    picker = (
        <FormGroup>
          <FormLabel>Period:</FormLabel>
          <FormControl className='input-date' type={"date"} placeholder={"Period date"} value={state.period} onChange={onPeriodChange}/>
        </FormGroup>
    );
  }

  return (
      <div>
        {picker}
        <div style={{width: '100%', height: 300}}>
          <ResponsiveContainer>
            <BarChart width={500} height={300} data={state.data}>
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="name"/>
              <YAxis/>
              <Tooltip content={<CustomTooltip/>}/>
              <Legend/>
              <Bar dataKey={state.fieldText} fill="#006086"/>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
  )
}

const CustomTooltip = ({active, payload, label}) => {
  if (active && payload && payload.length) {
    let formatter = new Intl.NumberFormat("sv-SE", {
      style: 'currency',
      currency: 'SEK'
    });
    const value = formatter.format(payload[0].value);
    return (
        <div className="card">
          <div className={"card-body"}>
            <p className="label">{`${label} : ${value}`}</p>
          </div>
        </div>
    );
  }

  return null;
};

YearMonthsBarChart.defaultProps = {
  field: "saleSum",
  initalPeriod: moment().format("YYYY-MM-DD"),
  showpicker: false,
};

export default YearMonthsBarChart;