import moment from "moment/moment";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import React from "react";
import {capitalize, getWeekDayFromDate} from "../firstDayOfPeriod";
import {useSettings} from "../../SettingsContext";
import {useFormatters} from "../formatters";

const CustomTooltip = ({active, payload, label, displayUnits, salesMode, compareMode}) => {
  const {settings}= useSettings();
  const formatters = useFormatters(settings);
  if (active && payload && payload.length) {
    let formatter = displayUnits?formatters.number:formatters.currency;

    let weekday = '';
    if (moment.isDate(label)){//TODO incorrect, isData just checks if it´s a Date object....
      weekday = capitalize(getWeekDayFromDate(moment(label).toDate()),settings.locale);
    }
    
    const formatDate = (winposDate) => {
      if(!winposDate)
        return '';
      return moment(winposDate, 'YYYYMMDD').format('YYYY-MM-DD');
    }
    
    const value = formatter.format(payload[0].value);
    
    return (
      <>
        <div className="card custom-tooltip">
          <div className={"card-body"}>
            {salesMode && (<>
                <h5>
                  {weekday !== '' &&(
                    <span>{weekday} -</span>
                  )} 
                  {label}
                </h5>
                <ul className={"list-group"}>
                  <li className={"list-group-item"}><span className="label">{`Netto : ${formatter.format(payload[0].value)}`}</span></li>
                  <li className={"list-group-item"}><span className="label">{`Moms : ${formatter.format(payload[1].value)}`}</span></li>
                  <li className={"list-group-item"}><span className="label">{`Totalt : ${formatter.format(payload[0].value+payload[1].value)}`}</span></li>
                </ul>
              </>
            )}

            {compareMode && (
              <>
                <h5>{label}</h5>
                <ul className={"list-group"}>
                  <li className={"list-group-item"}><span className="label">{`${formatDate(payload[0].payload?.periodId)}: ${formatter.format(payload[0].value)}`}</span></li>
                  <li className={"list-group-item"}><span className="label">{`${formatDate(payload[0].payload.compared?.periodId)}: ${formatter.format(payload[0].payload.comparedValue)}`}</span></li>
                </ul>
              </>
            )}
            
            {!salesMode && !compareMode && (
              <p className="label">{`${label} : ${value}`}</p>
            )}
          </div>
        </div>
      </>
    );
  }

  return null;
};

const SalesBarChart = (props) => {
  const {keyField, sales, compare, title, valueKey, salesMode, displayUnits} = props;
  const compareMode = compare != null && compare.length > 0;
  
  // console.log("sales", sales.map(x=> x.periodId));
  // console.log("compare", compare.map(x=> x.periodId));
    
  const findCompare = (item, idx,valueKey) => {
    let compareItem = compare?.find(c => c[keyField] === item[keyField])
    if(!compareItem){
      compareItem = compare[idx];//fall back to index position
    }
    
    if(compareItem == null)
      return null;
    return compareItem[valueKey];
  }
  
  const findCompareItem = (item,idx) => {
    let compareItem = compare?.find(c => c[keyField] === item[keyField])
    if(!compareItem){
      compareItem = compare[idx];//fall back to index position
    }
    
    if(compareItem == null){
      return null;
    }
    //console.log("findCompareItem", item, compareItem)
    return compareItem;
  }
  
  const data = sales.map((s,i) => {
    //console.log(s);
    let name = s[keyField];
    if(props.keyFormatter != null){
      const keyValue = s[keyField];
      name = props.keyFormatter(keyValue);
    }
    
      
    const item = {
      ...s,
      name: name,
      withTax: s.saleSum,
      tax: s.saleSum - s.saleTax0Sum
    };
    item[title] = s[valueKey]
    
    if(compareMode){
      item["compared"] = findCompareItem(s,i);
      item["comparedValue"] = findCompare(s,i, valueKey);
    }
    return item;
  });

  const chartResize = ()=>{
    console.log("chartResize");
  }
  
  return (
    <>
      <h3>{title} {compareMode}</h3>
      <div className={'bar-chart'}>
        <ResponsiveContainer debounce={200} width={"100%"} onResize={chartResize}>
          <ComposedChart data={data}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name"/>
            <YAxis/>
            <Tooltip content={<CustomTooltip compareMode={compareMode} displayUnits={displayUnits} salesMode={salesMode}/>}/>
            {salesMode && (
              <>
                <Bar dataKey={"withTax"} fill="#006086" stackId={'a'}/>
                <Bar dataKey={"tax"} fill="#ff6086" stackId={'a'}/>
              </>
            )}
            
            {compareMode && (
              <>
              <Bar dataKey={title} fill="#006086" stackId={'a'}/>
              <Line dataKey={"comparedValue"} strokeWidth={2} stroke="#FFA500" strokeDasharray="5 5"/>
              </>
            )}

            {!salesMode && !compareMode && (
              <Bar dataKey={title} fill="#006086"/>
            )}
            
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

export default SalesBarChart;