import {Shop} from "../types";
import {useTranslation} from "react-i18next";

interface Props {
    shop: Shop;
}

const ShopStatus = (props: Props) => {
    const {shop} = props;
    const {t} = useTranslation();
    return (
        <div className={'shop-status'}>
            <span className={`pnp-status status-${shop.status}`}>{t('pick-n-pay.shop-status-widget.status.' + shop.status)}</span>
            <span className={`shop-hours hours-${shop.openForBusiness?'open':'closed'}`}>
                {shop.openForBusiness && (<>{t('pick-n-pay.shop-status-widget.open')}</>)}
                {!shop.openForBusiness && (<>{t('pick-n-pay.shop-status-widget.closed')}</>)}
            </span>
        </div>
    );
};


export default ShopStatus;