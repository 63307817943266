import React from "react";
import moment from "moment/moment";
import TerminalSalesList from "../TerminalSalesList";
import {useStatistics} from "./hooks/useStatistics";
import StatisticValue from "../StatisticValue";
import {useTerminalStatistics} from "./hooks/useTerminalStatistics";
import {useArticleStatistics} from "./hooks/useArticleStatistics";
import Loading from "../Loading";
import SalesBarChart from "./SalesBarChart";
import {useRangeStatistics} from "./hooks/useRangeStatistics";
import {ArticleGroupList} from "./ArticleGroupList";
import {useTranslation} from "react-i18next";
import {useSettings} from "../../SettingsContext";

const MonthlyDashboard = (props) => {
  const {settings} = useSettings();
  const {period} = props;
  const {profitCenter, profitCenterArea} = props;
  const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
  const formatter = new Intl.DateTimeFormat(settings.locale, options);
  let parts = formatter.formatToParts(moment(props.period).toDate());
  
  const month = parts.find(r => r.type === 'month').value;
  
  const monthStart = moment(period).startOf('month').format('YYYY-MM-DD')
  const monthEnd = moment(period).endOf('month').format('YYYY-MM-DD');
  let compareMonthStart = null;
  let compareMonthEnd = null;
  
  if(props.compareTo){
    compareMonthStart = moment(props.compareTo).startOf('month').format('YYYY-MM-DD')
    compareMonthEnd = moment(props.compareTo).endOf('month').format('YYYY-MM-DD');
  }
  
  const {t} = useTranslation();
  const { stats, compareStats, statStatus} = useStatistics({ periodType: "Month", period: props.period, profitCenter, profitCenterArea, compare:props.compareTo });
  const { terminalStats, terminalTypeStats, statTermStatus, compareTerminalTypeStats, compareTerminalStats} = useTerminalStatistics({ periodType: "Month", period: props.period, profitCenter, profitCenterArea, compare:props.compareTo, count:100 });
  const { articleStats, articleCompareStats, articleStatStatus, getArticleStatsInGroup} = useArticleStatistics({ periodType: "Month", period: props.period, profitCenter, profitCenterArea, compare:props.compareTo, count:100 });
  const [ rangeStats, rangeCompareStats, rangeStatus ] = useRangeStatistics({
    returnArray: true,
    periodType:'Day',
    from:monthStart,
    to:monthEnd,
    compareFrom:compareMonthStart,
    compareTo:compareMonthEnd,
    profitCenter,
    profitCenterArea
  });
  
  const statuses = [statStatus, statTermStatus,articleStatStatus, rangeStatus];
  const loading =  statuses.some(s=>s.status === 'loading' || s.compareStatus === 'loading');
  
  return (
      <>
        <div className="tab-panel-header">
          
          <h2 className={"mt-3 text-center"} style={{textTransform: "capitalize"}}>
            {profitCenterArea && !profitCenter && (
                <span>{profitCenterArea.description} - </span>
            )}
            {profitCenter && (
                <span>{profitCenter.name} - </span>
            )}
            <span> {t('sales.monthly.month-of-name', {monthName: month})}</span>
            <Loading visible={loading} />
          </h2>
          <div className={"stats-summary stats-summary-month"}>
            {stats.length > 0 && (
                <>
                  <StatisticValue className={"m-auto"} text={t('sales.common.net-sales')}         value={stats[0].saleTax0Sum} compareValue={compareStats[0]?.saleTax0Sum}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.gross-sales')}       value={stats[0].saleSum} compareValue={compareStats[0]?.saleSum}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.purchases')}         value={stats[0].customerQty} formatter={'other'} compareValue={compareStats[0]?.customerQty}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.profit')}            value={stats[0].profitSum} compareValue={compareStats[0]?.profitSum}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.profit-percentage')} value={stats[0].profitPercent} formatter={'percent'} compareValue={compareStats[0]?.profitPercent}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.average-amount')}    value={stats[0].saleSum / stats[0].customerQty}  compareValue={compareStats[0]?.saleSum / compareStats[0]?.customerQty}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.discounts')}         value={stats[0].discSum} compareValue={compareStats[0]?.discSum}/>
                </>
            )}
          </div>
        </div>
        <div className="tab-panel-content">
          <div className="tab-content-wrapper">
            <div className={"chart"}>
              <SalesBarChart
                keyField={"periodId"}
                sales={rangeStats}
                compare={rangeCompareStats}
                title={t('sales.monthly.sales-count-per-week')}
                valueKey={'saleTax0Sum'}
                keyFormatter={(key)=>moment(key,'YYYYMMDD').format('DD')}
              />
            </div>
        
            <div className={"chart"}>
              <SalesBarChart
                keyField={"periodId"}
                sales={rangeStats}
                compare={rangeCompareStats}
                title={t('sales.monthly.sales-per-week')}
                valueKey={'customerQty'}
                displayUnits={true}
                keyFormatter={(key)=>moment(key,'YYYYMMDD').format('DD')}
              />
            </div>
        
            <div className={"group-sales-lists"}>
            <ArticleGroupList title={t('sales.common.article-groups')}
                              sales={articleStats}
                              compareStats={articleCompareStats}
                              expand={getArticleStatsInGroup}
            />
         
            <TerminalSalesList stats={terminalStats}
                                typeStats={terminalTypeStats}
                                compareStats={compareTerminalStats}
                                compareTypeStats={compareTerminalTypeStats}
                                showTerminalList={props.profitCenter != null}
                                
            />
            
          </div>
         </div>
        </div>
      </>
  )
}

export default MonthlyDashboard;