import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Loading from "../../Loading";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import {AdyenProvider, NetsEasyProvider, PaymentProvider, SwedBankPayProvider} from "../types";

import SwedBankItem from "./SwedBankItem";
import NetsEasyItem from "./NetsEasyItem";
import AdyenItem from "./AdyenItem";

interface State {
  createMode: boolean,
  pageIndex: number,
  providers: PaymentProvider[]
}

const PaymentProviders = () => {
  const {t} = useTranslation();
  const {targetRef, isIntersecting} = useIntersectionObserver({threshold: 0.5});
  const [loading, setLoading] = useState(false);
  const [enabledProviders, setEnabledProviders] = useState<string[]>([]);
  const [state, setState] = useState<State>({
    createMode: false,
    pageIndex: 0,
    providers: []
  });
  
  const [newProvider, setNewProvider] = useState<SwedBankPayProvider|NetsEasyProvider|AdyenProvider | null>(null);
  const [selectProvider, setSelectProvider] = useState<string | null>(null);
  
  useEffect(() => {
    setLoading(true);
    
    const promises = [
      fetch('api/pick-n-pay/payment-providers')
        .then(response => response.json())
        .then(data => {
          setState({
            ...state,
            providers: data.items
          });
        }),
      fetch('api/pick-n-pay/settings')
        .then(r => r.json())
        .then(data => {
          setEnabledProviders(data.enabledPaymentProviders ?? [])
      })
    ];
    Promise.all(promises).then(() => {
      
      setLoading(false);
    });
    
  }, []);
  
  
  const getJsonType = (type:string):string =>{
    if(type === 'SwedbankPay'){
      return 'swedbank-pay';
    }
    if(type === 'NetsEasy'){
      return 'nets-easy';
    }

    if(type === 'Adyen'){
      return 'adyen';
    }
    
    throw new Error('Unknown type');
  }
  
  const onCreate = (provider:PaymentProvider)=>{
    provider.$type = getJsonType(provider.type);
    fetch('api/pick-n-pay/payment-providers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(provider, Object.keys(provider).sort())
    }).then(r => r.json())
      .then(data => {
        setState({
          ...state,
          providers: [...state.providers, data]
        });
      });  
  }
  
  const onSave = (provider:PaymentProvider)=>{
    fetch(`api/pick-n-pay/payment-providers/${provider.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(provider)
    }).then(r => r.json())
      .then(data => {
        setState({
          ...state,
          providers: state.providers.map((p:PaymentProvider) => p.id === data.id ? data : p)
        });
      });
  }
  
  const onCreateTypeChanged = (e:any)=>{
    setSelectProvider(e.target.value);
    
  }
  
  const onDelete = (provider:PaymentProvider)=>{
    fetch(`api/pick-n-pay/payment-providers/${provider.id}`, {
      method: 'DELETE'
    }).then(r => {     
        setState({
          ...state,
          providers: state.providers.filter((p:PaymentProvider) => p.id !== provider.id)
        });
      });
  };
  
  const onCancelCreate = ()=>{
    setState({...state, createMode: false})
    setSelectProvider(null);
    setNewProvider(null);
  };
  
  const createTemplate = ()=>{
    if(selectProvider === 'SwedbankPay') {
      setNewProvider({ $type:'swedbank-pay',name: '', type: 'SwedbankPay', id: 0, payeeId: '', token: '', apiBaseUrl:''  });
    }
    else if (selectProvider === 'NetsEasy') {
      setNewProvider({ $type:'nets-easy', name: '', type: 'NetsEasy', id: 0, checkoutKey: '', apiKey: '', clientMode:'', jsSrc:'' });
    }
    else if (selectProvider === 'Adyen') {
      setNewProvider({ $type:'adyen', name: '', type: 'Adyen', id: 0, apiKey: '', clientKey: '', hmacKey:'', merchantAccount:'', environment:''});
    }
  }

  if (loading) {
    return (<Loading visible={true}/>);
  }
  
  return (<div className={"pick-n-pay payment-providers"}>
    <div className={"filter-container"}>
      <h1>{t('pick-n-pay.payment-providers.header')}</h1>
      <div className={"filter"}>
        <div className="input-group">
          <label className="form-label input-group-text">{t('pick-n-pay.payment-providers.search')}</label>
          <input className="form-control" type="search"/>
        </div>
        <button className={"btn btn-add"} onClick={()=>setState({...state,createMode:true})}>{t('pick-n-pay.payment-providers.addProvider.addButton')}</button>
      </div>
      {state.createMode && (
        <div className={"create-panel"}>
          <h3>{t('pick-n-pay.payment-providers.addProvider.header')}</h3>
          <div className={"input-group"}>
            <label>{t('pick-n-pay.payment-providers.addProvider.providerPicker.label')}</label>
            <select value={selectProvider ??""} onChange={onCreateTypeChanged} className={"form-control"}>
              <option disabled={true} value="">Välj</option>
              {enabledProviders.map(p => (
                <option key={p} value={p}>{t(`common.payment-providers.${p}`)}</option>
              ))}
            </select>
            <button className={"btn"} onClick={createTemplate}>{t('pick-n-pay.payment-providers.addProvider.providerPicker.button')}</button>
          </div>
          
          {newProvider && newProvider.type === 'SwedbankPay' && <SwedBankItem provider={newProvider} createMode={true} editMode={true} onSave={onCreate}/>}
          {newProvider && newProvider.type === 'NetsEasy' && <NetsEasyItem provider={newProvider} createMode={true} editMode={true} onSave={onCreate}/>}
          {newProvider && newProvider.type === 'Adyen' && <AdyenItem provider={newProvider} createMode={true} editMode={true} onSave={onCreate}/>}
          
          <button className={"btn btn-close"} onClick={onCancelCreate}>
            <span className={"sr-only"}>Close</span>
          </button>
        </div>
      )}

      <ol className={"provider-list"}>
        {state.providers.map(provider => (
         <li key={provider.name}>
            {provider.type === 'SwedbankPay' && <SwedBankItem provider={provider} onSave={onSave} onDelete={onDelete}/>}
            {provider.type === 'NetsEasy' && <NetsEasyItem provider={provider} onSave={onSave} onDelete={onDelete}/>}
            {provider.type === 'Adyen' && <AdyenItem provider={provider} onSave={onSave} onDelete={onDelete}/>}
        </li>
      ))}
      </ol>
      
      <div ref={targetRef}></div>
    </div>
  </div>)
};









export default PaymentProviders