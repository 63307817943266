import {FormControl, FormLabel, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import React, {ChangeEvent, FormEvent, useState} from "react";
import {useTranslation} from "react-i18next";

const ResetPassword = () => {
  const [state, setState] = useState({email: ""});
  const [showMessage, setShowMessage] = useState(false);
  const {t} = useTranslation(); 
  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value
    const name = target.name;

    setState({
          ...state,
          [name]: value
        }
    );
  }
  const submit = (event:FormEvent) => {
    event.preventDefault();
    
    fetch('/account/reset-password', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(state)
    }).then(resp=>{
      setShowMessage(true);
    });

  }


  return (
      <>
        <div className={"login"}>
          <form onSubmit={submit}>
            <h3>{t('reset-password.header')}</h3>
            <p>
                {t('reset-password.description')}
            </p>
              
            <p>
                {t('reset-password.delay')}
            </p>

            <div className={"input-group"}>
              <FormLabel htmlFor={"email"}>{t('reset-password.email.label')}</FormLabel>
              <FormControl id={"email"} name={"email"} type={"email"} value={state.email} onChange={handleChange}/>
            </div>

            


            {showMessage && (
                <div className="alert alert-success mt-3" role="alert">
                  <p>{t('reset-password.message.text1')}</p>
                  <p>
                      {t('reset-password.message.text2')}
                      <Link to={'/login'} className={"alert-link"}>
                          {t('reset-password.message.text3')}
                      </Link>
                      {t('reset-password.message.text4')}</p>
                </div>
            )}
            <div className={'login-footer'}>
              <Button className={"btn"} type={"submit"} onClick={submit}>
                  {t('reset-password.submit.text')}
              </Button>
            </div>
          </form>
        </div>
      </>
  )
}
export default ResetPassword;