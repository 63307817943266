import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Layout from './components/Layout';
import './style/style.scss';
import {UserProvider, useUser} from "./UserContext";
import {SettingsProvider} from "./SettingsContext";
import Home from "./components/Home";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import SetNewPassword from "./components/SetNewPassword";
import CreateUser from "./components/CreateUser";
import Users from "./components/Users";
import EditUser from "./components/EditUser";
import Prices from "./Prices";
import PickAndPay from "./PickAndPay";
import Profile from "./Profile";
import Campaigns from "./components/PickAndPay/Campaigns";
import Stickers from "./components/PickAndPay/Stickers";
import Shops from "./components/PickAndPay/shops/Shops";
import Settings from "./components/PickAndPay/settings/Settings";
import TermsOfService from "./components/PickAndPay/TermsOfService";
import PaymentProviders from "./components/PickAndPay/payment-providers/PaymentProviders";
const App = () => {

  return (
    <SettingsProvider>
      <UserProvider>
        <Layout>
          <Routes>
            <Route path={'/'} element={<Home/>}/>
            <Route path={'/login'} element={<Login/>}/>
            <Route path={'/reset-password'} element={<ResetPassword/>}/>
            <Route path={'/reset-password/set-password'} element={<SetNewPassword/>}/>

            <Route path={'/admin/users'}>
              <Route path={'/admin/users/'} element={<Users/>} />
              <Route path={'create'} element={<CreateUser/>}/>
              <Route path={':userId'} element={<EditUser/>}/>
            </Route>

            <Route path={'/prices'} element={<Prices/>}/>
            <Route path={'/pick-n-pay'}>
              <Route path={'/pick-n-pay/'} element={<PickAndPay/>}/>
              <Route path={'campaigns'} element={<Campaigns/>}/>
              <Route path={'stickers'} element={<Stickers/>}/>
              <Route path={'shops'} element={<Shops/>}>
                <Route path={':shopId'} element={<Shops/>}/>  
              </Route>
              <Route path={'terms'} element={<TermsOfService/>}/>
              <Route path={'settings'} element={<Settings/>}>
                <Route path={'settings'} element={<Settings/>}/>                 
              </Route>
              <Route path={"settings/payment-providers"} element={<PaymentProviders/>}/>
              
            </Route>
            <Route path={'/profile'} element={<Profile/>}/>
          </Routes>

        </Layout>
      </UserProvider>
    </SettingsProvider>
  );
}
export default App;