import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import i18n from "i18next";

interface SettingsContextType {
    currency: string | undefined;
    locale: string | undefined;
    supportedLocales: string[];
    daily: {
        hourlySales: boolean,
        quarterHourSales: boolean,
        hourlyPurchases: boolean,
        quarterHourPurchases: boolean
    },
    showPriceListInterface: boolean,
    showWebOrderInterface: boolean,
    enableExports: boolean,
    exportCronSchedule: string,
    version: string
}

const getDefaultSettings = () => {
    return {
        "daily": {
            "hourlySales": true,
            "quarterHourSales": false,
            "hourlyPurchases": true,
            "quarterHourPurchases": false
        },
        "supportedLocales": ["en","sv","no"],
        "showPriceListInterface": true,
        "showWebOrderInterface": true,
        "locale": "en",
        "currency": "SEK",
        "enableExports": false,
        "exportCronSchedule": "0 1 * * *",
        "version": "0.0.0.0"
    }
};

export const SettingsContext = createContext<SettingsContextType>(getDefaultSettings());

export const SettingsProvider = ({children}: PropsWithChildren<{}>) => {
    const [settings, setSettings] = useState<SettingsContextType>(getDefaultSettings());
    const [locale, setLocale] = useState<string | undefined>();
    i18n.on('languageChanged', (lng:string) => {
        setLocale(lng);
    });
    useEffect(() => {
        fetch('/api/settings')
            .then(response => response.json())
            .then(settings => {
                if(localStorage.getItem("locale")){
                    settings.locale = localStorage.getItem("locale");
                    console.log('found locale in localstorage, setting to ', settings.locale);
                }
                settings.supportedLocales = settings.supportedLocales || ["en","sv","fi"];
                setSettings(settings);
            })
            .catch(err => {
                console.log(err);
            });
    }, [locale]);

    return (
        <SettingsContext.Provider value={settings}>
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettings = () => {
    return {settings: useContext(SettingsContext)}
};