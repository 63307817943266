import React, {ChangeEvent, KeyboardEvent} from "react";
import {useTranslation} from "react-i18next";


export type OptionValue = string|number|null;
export interface Option {
  value: OptionValue,
  label: string
}

interface Props {
  label?: string,
  className?: string,
  value?:OptionValue,
  options:Option[],
  editable?:boolean,
  emptyText?:string,
  onChange?:(value:OptionValue)=>void
}


const Picker = (props:Props)=>{
  const {t} = useTranslation();
  const [selectedOption, setSelectedOption] = React.useState<Option|null>(props.options.find(t=>t.value == props.value) ?? null);
  const [editing, setEditing] = React.useState<boolean>(false);
  const [query, setQuery] = React.useState<string>("");
  const [filteredOptions, setFilteredOptions] = React.useState<Option[]>(props.options);
  
  React.useEffect(()=>{
    setSelectedOption(props.options.find(t=>t.value == props.value) ?? null);
    updateList('');
  },[props.options]);
  
  
  const onInputChanged = (e:ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    updateList(e.target.value);
  }

  const updateList  = (filter:string) =>{
    const filtered = props.options.filter(t=>t.label.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
    setFilteredOptions(filtered);
  }

  const onItemClicked = (option:Option) => {
    setSelectedOption(option);
    props.onChange && props.onChange(option.value);
    setEditing(false);
    setQuery('');
    updateList('');
  };

  const closeDialog = () => {
    setEditing(false);
    setQuery('');
    updateList('');
  };
  
  const onKeyDown = (e:KeyboardEvent<HTMLDivElement>) => {
    if(e.key == "Escape") {
      closeDialog();
    }
  };
  
  return (
    <>
      <div className={`input-group fancy-picker fancy-dropdown ${(props.editable??true) ? "editable" : ""} ${(editing??true) ? "open" : ""}`} onKeyDown={onKeyDown}>
        {props.label && (
          <label className={"form-label input-group-text"}>{props.label}</label>
        )}
        <div className={"fancy-picker-select"}>
          {selectedOption && (
          <span className={"selected-item"}>
            <span className={"selected-item-label"}>{selectedOption?.label}</span> 
            <span className={"selected-item-value"}> ({selectedOption?.value})</span>
          </span>
          )}

          {!selectedOption && (
            <span className={"selected-item empty"}>
                {props.emptyText ?? t('components.picker.empty')}
            </span>
          )}
          
          {(props.editable?? true) && (
            <button className={"btn btn-dropdown"} onClick={()=>setEditing(true)}>
              <span className={"sr-only"}>{t('components.picker.edit')}</span>
            </button>
          )}
        
        
          {editing && ( 
            <div className={"fancy-picker-dropdown"}>
              <div className={"input-group"}>
                <label className={"form-label input-group-text"}>{t('components.picker.search')}</label>
                <input className={"form-control"} type={"search"} value={query} onChange={onInputChanged}/>
                <button className={"btn btn-close"} onClick={closeDialog}>
                  <span className={"sr-only"}>{t('components.picker.search')}</span>
                </button>
              </div>
              <ul>
                {filteredOptions.map(t => (
                  <li key={t.value} onClick={()=>onItemClicked(t)} className={(t.value == selectedOption?.value)?"selected":""}>
                    <span className={"option-label"}>{t.label}</span>
                    <span className={"option-value"}>{t.value}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      {editing && (<div className="dialog-backdrop" onClick={closeDialog}></div>)}
    </>
  );
}

export default Picker;