import {useEffect, useRef, useState} from "react";

const useIntersectionObserver = (options:any, margin?:'0px')=>{
  const [isIntersecting, setIsIntersecting] = useState(false);
  const targetRef = useRef(null);
  
  useEffect(()=>{
    const observer = new IntersectionObserver(
      entries =>{
        entries.forEach(entry=>{
          setIsIntersecting(entry.isIntersecting);
        })
      }, {
        ...options,
        rootMargin: margin
      }
    );
    
    if(targetRef.current){
      observer.observe(targetRef.current);
    }
    
    return () => {
      if(targetRef.current){
        observer.unobserve(targetRef.current);
      }
    }
  },[options,margin]);
  
  return { targetRef, isIntersecting}
}
export default useIntersectionObserver;