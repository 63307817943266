import {useTraceUpdate} from "./components/Dashboard/hooks/useTraceUpdate";
import {ChangeEvent, useEffect, useState} from "react";
import {getI18n, I18n, setI18n} from "./components/PickAndPay/types";

interface I18nInputProps {
    i18n: I18n|null;
    i18nKey: string;
    locale:string;
    defaultValue?:string
    onChange?: (value:I18n|null)=>void;
}

const I18nInput = (props: I18nInputProps) => {
    //useTraceUpdate(props, "I18nInput");
    const [value, setValue] = useState<string>("");

    useEffect(() => {
        setValue(getI18n(props.i18n!, props.i18nKey, props.locale, props.defaultValue) || "")
    }, [props.locale]);

    const change = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        setI18n(props.i18n!, props.i18nKey, props.locale, e.target.value);

        props.onChange && props.onChange(props.i18n);
    }

    return (
        <>
            <input type={"text"} onChange={change} value={value}/>
        </>
    )
}

interface I18nSpanProps {
    i18n: I18n|null;
    i18nKey: string;
    locale:string;
    html?:boolean;
    div?:boolean;
    className?:string;
    fallback?:string;
}

export const I18nSpan = (props:I18nSpanProps)=>{
    const text = props.i18n ? getI18n(props.i18n, props.i18nKey, props.locale, props.fallback):props.fallback??"";
    
    if(props.html && !props.div)
        return (<span className={props.className} dangerouslySetInnerHTML={{__html: text ?? ""}}></span>)
    if(props.html && props.div)
        return (<div className={props.className} dangerouslySetInnerHTML={{__html: text ?? ""}}></div>)

    if(props.div)
        return (<div className={props.className}>{text}</div>)
    return (<span className={props.className}>{text}</span>)
}
export default I18nInput;