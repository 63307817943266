import React, {useEffect, useState} from "react";
import firstDayOfPeriod from "./firstDayOfPeriod";
import Loading from "./Loading";
import {ChevronDown, ChevronUp} from "react-bootstrap-icons";
const SdptSalesList = (props) => {
  const {stats, periodType, profitCenter, profitCenterArea, compareStats } = props;
    
  const sorted = stats?.sort((a,b) => b.saleSum - a.saleSum);
  const findCompareItem = (item) => {
    return compareStats?.find(i => i.code === item.code);
  }
  
  const show = true;

  return (
      <>
        {show &&(
        <div className={props.className}>
          <h2>Mest sålda Artikelgrupper</h2>
          <ul className={"list-group"}>
            {sorted.map(row => {
              return (
                <SdtpSalesItem 
                  key={row.code+Math.random()} 
                  item={row} 
                  compareItem={findCompareItem(row)}
                  periodType={periodType} 
                  profitCenter={profitCenter} 
                  profitCenterArea={profitCenterArea}
                />
              )
            })}
          </ul>
        </div>
        )}
      </>
  ); 
};

const SdtpSalesItem = (props) => {
  const {item, periodType, profitCenter, profitCenterArea, compareItem } = props;
  const [showDetails, setShowDetails] = useState(false);
  const formatter = new Intl.NumberFormat("sv-SE", {
    style: 'currency',
    currency: 'SEK'
  });

  const doShowDetails = () => {
    setShowDetails(!showDetails);
  }
  
  const diff = (a,b)=>{
    return a -b;
  };
  const getClassName = (calcValue) => {
    if (calcValue === 0) {
      return "text-secondary"
    }

    if (calcValue > 0)
      return "text-success";
    return "text-danger";
  };
  
  const formattedSaleSum = formatter.format(item.saleSum);

  return (
      <li className="list-group-item d-flex justify-content-between align-items-start" onClick={doShowDetails}>
        <div className="ms-2 me-auto">
          <div className="fw-bold">
            {item.description} 
            <span className={"statistic-value"}>({item.saleQty} st)</span>
          </div>
          <span className="badge bg-primary rounded-pill">{item.dptName}</span>
          {compareItem && (
            <span className={getClassName(diff(item.saleSum,compareItem.saleSum))}>
                {item.saleSum - compareItem.saleSum > 0 && <ChevronUp/>}
              {item.saleSum - compareItem.saleSum < 0 && <ChevronDown/>}
              
              {formatter.format(diff(item.saleSum, compareItem.saleSum))}
              
            </span>
          )}
          
          {showDetails && (
              <PluDetails profitCenter={profitCenter} profitCenterArea={profitCenterArea} dptCode={item.code} period={item.periodId} periodType={periodType}/>
          )}
        </div>
        <span className={"statistic-value"}>{formattedSaleSum}</span>
      </li>
  )
};

const PluDetails = (props) => {
  const {period, periodType, profitCenter, dptCode, profitCenterArea } = props;
  const [data, setData] = useState([]);

  useEffect(() => {

    let qs = `profitCenterId=${profitCenter?.code ?? ""}`;
    if(profitCenter == null && profitCenterArea != null){
      qs = `areaId=${profitCenterArea?.code ?? ""}`;
    }
    fetch(`/api/plu-sales?${qs}&periodType=${periodType}&period=${period}&count=500&dptCode=${dptCode}`)
        .then((response) => response.json())
        .then((data) => {
          setData(data.sales);
        });
  }, [period, periodType, profitCenter, dptCode])

  if (data === [])
    return (<span>No data</span>);

  const formatter = new Intl.NumberFormat("sv-SE", {
    style: 'currency',
    currency: 'SEK'
  });

  return (
      <>
      <ul className={"list-group mt-3"}>
        {data.map(item => (
            <li key={item.code+Math.random()} className="list-group-item d-flex justify-content-between align-items-start">
              <div className="ms-2 me-auto">
                <div>{item.description} <span className={"statistic-value"}>({item.saleQty} st)</span></div>
                <span className="badge bg-primary rounded-pill">{item.dptName}</span>
              </div>
              <span className={"statistic-value"}>{formatter.format(item.saleSum)}</span> 
            </li>
        ))}
      </ul>
      </>
  )

}

// "saleDptId": 42302,
//   "code": "1001      ",
//   "profitCenter": 57,
//   "profitCenterName": "Sibylla Hallsberg",
//   "period": "Day",
//   "periodId": 20221001,
//   "periodTime": "    ",
//   "saleQty": 144,
//   "saleSum": 8411,
//   "saleTax0Sum": 7509.68,
//   "profitSum": 5244.8626,
//   "profitPercent": 69.8413,
//   "description": "Menyer:Burgare",
//   "dptCode": "10        ",
//   "dptName": "Mat"


SdptSalesList.defaultProps = {
  count: 10
};
export default SdptSalesList;