import React from "react";
import firstDayOfPeriod, {getDateInWeek, getWeekDayFromDate} from "../firstDayOfPeriod";
import moment from "moment/moment";
import SdptSalesList from "../SdptSalesList";
import TerminalSalesList from "../TerminalSalesList";
import StatisticValue from "../StatisticValue";
import {useStatistics} from "./hooks/useStatistics";
import {useTerminalStatistics} from "./hooks/useTerminalStatistics";
import {useArticleStatistics} from "./hooks/useArticleStatistics";
import SalesBarChart from "./SalesBarChart";
import Loading from "../Loading";
import {useRangeStatistics} from "./hooks/useRangeStatistics";
import {ArticleGroupList} from "./ArticleGroupList";
import {useTranslation} from "react-i18next";
import {useSettings} from "../../SettingsContext";

const WeeklyDashboard = (props) => {
  const {settings} = useSettings();
  const {profitCenter, profitCenterArea, compareTo} = props;
  const startOfWeek = moment(props.period).startOf("isoWeek").format("YYYY-MM-DD")
  const endOfWeek = moment(props.period).endOf("isoWeek").format("YYYY-MM-DD")
  
  let compareStartOfWeek = null;
  let compareEndOfWeek = null;

  if(props.compareTo){
    compareStartOfWeek = moment(props.compareTo).startOf('isoWeek').format('YYYY-MM-DD')
    compareEndOfWeek = moment(props.compareTo).endOf('isoWeek').format('YYYY-MM-DD');
  }
   
  
  const weekNumber = moment(props.period).isoWeek();
  
  const startDisplayDate = new Intl.DateTimeFormat(settings.locale, {dateStyle: 'full'}).format(moment(startOfWeek).toDate())
  const endDisplayDate = new Intl.DateTimeFormat(settings.locale, {dateStyle: 'full'}).format(moment(endOfWeek).toDate())
  const datesInWeek = getDateInWeek(startOfWeek);
  
  const {stats, compareStats, statStatus} = useStatistics({ periodType: "Week", period: props.period, profitCenter, profitCenterArea, compare:props.compareTo});
  const { terminalStats, terminalTypeStats, compareTerminalStats, compareTerminalTypeStats, statTermStatus} = useTerminalStatistics({ periodType: "Week", period: props.period, profitCenter, profitCenterArea, compare:props.compareTo, count:100 });
  const { articleStats, articleCompareStats, articleStatStatus, getArticleStatsInGroup} = useArticleStatistics({ 
    periodType: "Week", 
    period: props.period, 
    profitCenter, profitCenterArea, 
    compare:props.compareTo, 
    count:100 });

  const [ rangeStats, rangeCompareStats, rangeStatus ] = useRangeStatistics({ 
    returnArray: true, 
    periodType:'Day',
    from:startOfWeek, 
    to:endOfWeek,
    compareFrom:compareStartOfWeek,
    compareTo:compareEndOfWeek,
    profitCenter, 
    profitCenterArea
  });
  const {t}  = useTranslation();
  
  const statuses = [statStatus, statTermStatus, articleStatStatus, rangeStatus];
  const loading =  statuses.some(s=>s.status === 'loading' || s.compareStatus === 'loading');
  
  
  return (
      <> 
        <div className="tab-panel-header">
          
          <h2 className={"mt-3 text-center"} title={`${startOfWeek} till ${endOfWeek}`} style={{textTransform: "capitalize"}}>
            {profitCenterArea && !profitCenter &&(
                <span>{profitCenterArea.description} - </span>
            )}
            {profitCenter && (
                <span>{profitCenter.name} - </span>
            )}
            <span>{t('common.week')} {weekNumber}</span>
            <span className={"text-secondary"} style={{textTransform: "capitalize"}}> ({startDisplayDate} - {endDisplayDate})</span>
            <Loading visible={loading} />
          </h2>
          
          <div className={"stats-summary"}>
            {stats.length > 0 && (
                <>
                  <StatisticValue className={"m-auto"} text={t('sales.common.net-sales')}         value={stats[0].saleTax0Sum} compareValue={compareStats[0]?.saleTax0Sum}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.gross-sales')}       value={stats[0].saleSum} compareValue={compareStats[0]?.saleSum}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.purchases')}         value={stats[0].customerQty} formatter={'other'} compareValue={compareStats[0]?.customerQty}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.profit')}            value={stats[0].profitSum} compareValue={compareStats[0]?.profitSum}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.profit-percentage')} value={stats[0].profitPercent} formatter={'percent'} compareValue={compareStats[0]?.profitPercent}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.average-amount')}    value={stats[0].saleSum / stats[0].customerQty}  compareValue={compareStats[0]?.saleSum / compareStats[0]?.customerQty}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.discounts')}         value={stats[0].discSum} compareValue={compareStats[0]?.discSum}/>
                </>
            )}
          </div>
        </div>
        <div className="tab-panel-content">
          <div className="tab-content-wrapper">
            <div className={"chart"}>
              <SalesBarChart
                keyField={"periodId"}
                sales={rangeStats}
                compare={rangeCompareStats}
                title={t('sales.weekly.sales-per-day')}
                valueKey={'saleTax0Sum'}
                keyFormatter={(key) => getWeekDayFromDate(moment(key,'YYYYMMDD').toDate(), true, settings.locale)}
              />
            </div>
            <div className={"chart"}>
              <SalesBarChart
                keyField={"periodId"}
                sales={rangeStats}
                compare={rangeCompareStats}
                title={t('sales.weekly.sales-count-per-day')}
                displayUnits={true}
                valueKey={'customerQty'}
                keyFormatter={(key) => getWeekDayFromDate(moment(key,'YYYYMMDD').toDate(), true, settings.locale)}
              />
            </div>
            <div className={"group-sales-lists"}>
              <ArticleGroupList title={t('sales.common.article-groups')}
                                sales={articleStats}
                                compareStats={articleCompareStats}
                                expand={getArticleStatsInGroup}
              />
              <TerminalSalesList  showTerminalList={props.profitCenter != null}
                                stats={terminalStats}
                                typeStats={terminalTypeStats}
                                compareStats={compareTerminalStats}
                                compareTypeStats={compareTerminalTypeStats}
              />
            </div>
          </div>
        </div>
      </>
  )
}

export default WeeklyDashboard;