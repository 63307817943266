import React, {useEffect, useState} from "react";
import {I18n, Terms} from "./types";
import TextEditor from "../TextEditor";
import Loading from "../Loading";
import LocaleSelector from "./LocaleSelector";
import {useTranslation} from "react-i18next";
import ButtonConfirm from "./settings/ButtonConfirm";
import SaveChangesMessage from "../SaveChangesMessage";
import {usePnpLocales} from "./hooks/usePnpLocales";

const TermsOfService = () => {
  const {t}  = useTranslation();
  const {locales} = usePnpLocales();
  const [terms, setTerms] = useState<Terms|null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loc, setLoc] = useState<string>("en");
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  
  useEffect(() => {
    setLoading(true)
    fetch("/api/pick-n-pay/terms")
      .then((response) => response.json())
      .then((data) => {
        const terms = data as Terms;
        setTerms(terms);
        setLoading(false);
      });
  },[]);

  const setI18n = (i18n: I18n | null) => {
    setTerms({
       ...terms, 
      i18n: i18n
    });
  }
  
  const save = () => {
    fetch("/api/pick-n-pay/terms", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(terms),
    }).then(response => {
      if (response.ok)
        return response.json();
      throw new Error("Error updating terms");
    }).then(data => {
        setSaveSuccess(true);
    })
  };
  
  if(loading)
    return(<Loading visible={true} />)
  
  return (<div className={"pick-n-pay terms-of-service"}>
    <h1>{t("pick-n-pay.terms.header")}</h1>
    <LocaleSelector onChange={(locale) => setLoc(locale)} locale={loc} locales={locales} i18n={terms?.i18n}/>
    
    <TextEditor initialContentI18n={terms?.i18n} locale={loc} i18nKey={"text"} onChange={(content, contentHtml, updatedI18n)=>{
      setI18n(updatedI18n!);
    }} />
    
    <div className={"terms-footer form-footer"}>
      <ButtonConfirm title={t(`pick-n-pay.terms.save.description`)}
                     className={"btn btn-save"}
                     message={t(`pick-n-pay.terms.save.confirmText`)}
                     onConfirm={save}>
        {t(`pick-n-pay.terms.save.label`)}
      </ButtonConfirm>
      <SaveChangesMessage show={saveSuccess} onShowDone={()=> setSaveSuccess(false)} />
    </div>
  </div>)
};

export default TermsOfService;