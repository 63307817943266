import React, {useEffect} from "react";
import {Authenticate} from "./Authenticate";
import {useNavigate} from "react-router-dom";
import {User} from "oidc-client";

export const UserContext = React.createContext<User|null>(
    null//{ email:"", displayName:"",isAdministrator:false}
);

type Props = {
    children: string | JSX.Element | JSX.Element[] 
}

export const UserProvider = ({children}:Props) => {
    const navigate = useNavigate();
    const [user, setUser] = React.useState(null);
    const handleLogin = async (email:string, password:string)=>{
        let success = true;
        let profile = await Authenticate(email,password)
            .catch((err)=>{
                success = false;
            });
        setUser(profile);
        return success;
    }
    
    const handleLogout = ()=>{
        fetch('/account/logout').then(()=>{
            setUser(null);
            navigate('/');
        })
    }
    
    const value = {
        user,
        onLogin: handleLogin,
        onLogout: handleLogout,
    }

    useEffect(()=>{
        fetch('/account/profile')
            .then(response => response.json())
            .then(profile => {
                setUser(profile);
            })
            .catch(err => {
                //navigate("/login");
            });
    },[]);
    
    
    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    )
}

export const useUser = ()=>{
    return React.useContext(UserContext);
}