export type ContactInformation = {
    address1: string;
    address2: string;
    city: string;
    zipCode: string;
    phone: string;
    email: string;
    website: string;
}

export type Location = {
    latitude: number;
    longitude: number;
}

export type OpeningHours = {
    id: number;
    opens: string;
    closes: string;
    dayOfWeek: string;
    validFrom: string;
    validThrough: string;
    closed: boolean;
    isOverride: boolean;
    overrideName: string|null;
    date: Date | null |undefined;
}

export type ResponseError = {
    detail: string;
    status: number;
    title: string;
    type: string;
}

export type Shop = {
    id: number;
    name: string;
    description: string;
    shopId: string;
    status: string;
    contactInformation: ContactInformation;
    organizationId: string;
    location: Location;
    openingHours: OpeningHours[];
    externalAppUri?: any;
    merchantId: string;
    openForBusiness: boolean;
    terminalId: number;
    paymentProviderId: number|null
    imageId:string|null;
    winposOnly: boolean;
}

export interface PaymentProvider {
    '$type': string
    name: string,
    type: string
    id: number,
    usage?: PaymentProviderUsage[]
}

export interface PaymentProviderUsage {
    shopId: number,
    shopName: string,
}

export interface SwedBankPayProvider extends PaymentProvider {
    type: 'SwedbankPay',
    payeeId: string
    token: string,
    apiBaseUrl: string,
}

export interface NetsEasyProvider extends PaymentProvider {
    type: 'NetsEasy',
    checkoutKey: string,
    apiKey: string,
    clientMode: string,
    jsSrc: string,
}

export interface AdyenProvider extends PaymentProvider {
    type: 'Adyen',
    clientKey: string,
    apiKey: string,
    hmacKey: string,
    merchantAccount: string,
    environment: string,
}

export type Items = {
    code: string;
    typeOfCode: string;
    type: string;
    rowNr: number;
    price: number;
    codeTargetName: string;
}

export type Conditions = {
    type: string;
    conditionString: string;
    conditionDisplayText: string;
}

export interface Campaign {
    id?: any;
    code: string;
    name: string;
    winposName: string;
    description: string;
    enabled: boolean;
    winposEnabled: boolean;
    image?: any;
    items: Items[];
    conditions: Conditions[];
    i18n: I18n | null;
    stickers?:Sticker[];
    articleCodeLink?: string |null;
}

export type I18n = {
    translations: I18nLocale[];
}


export const hasI18nLocale = (i18n:I18n, locale:string):boolean =>{
    const loc = i18n?.translations?.find(x=>x.locale == locale);
    if(loc == undefined)
        return false;
    if(loc.entries.length ==0)
        return false;
    
    return loc.entries.find(s=>s.value != null ||s.value != "") != undefined;
}

export const  getI18n = (i18n:I18n, key:string, locale:string, fallback?:string)=>{
    const loc = i18n.translations.find(x=>x.locale == locale);
    if(loc == undefined)
        return fallback;
    return loc.entries.find(x=>x.key == key)?.value;
}

export const setI18n = (i18n:I18n, key:string, locale:string, value:string)=>{
    let loc = i18n.translations.find(x=>x.locale == locale);
    if(loc == undefined){
        loc = { locale: locale, entries: []};
        i18n.translations.push(loc);
    }
    let entry = loc.entries.find(x=>x.key == key);
    if(!entry)
        loc.entries.push({key: key, value: value});
    else
        entry.value = value;
};

export const logI18n = (i18n:I18n)=>{
    
    i18n.translations.forEach(loc=>{
        console.log(`-------------start "${loc.locale}"-----------------`);
        console.log(loc.locale);
        loc.entries.forEach(entry=>{
            console.log(entry.key + " : " + entry.value);
        })
        console.log(`-------------end "${loc.locale}"-----------------`);
    });
}

export type I18nLocale = {
    locale:string;
    entries: I18nEntry[];
}

export type I18nEntry = {
    key:string;
    value:string|null;
}

export type Sticker = {
    selected?: boolean;
    id: number;
    name: string;
    description: string;
    i18n: I18n;
}
export enum StickerType {
    Campaign = "Campaign",
    Article = "Article"
}

// export interface CampaignSticker extends Sticker{
//     campaign: Campaign;
// }
//
// export interface ArticleSticker extends Sticker{
//     articleCode: string;
// }

export type ArticleResult = {
    id:string,
    name:string,
    price:number,
    directSale:boolean,
    webSale:boolean,
    additionalInfo:string,
    subDepartmentCode:string,
    subDepartmentName:string,
    stickers?:Sticker[]
}

export type Terminal = {
    id:number,
    name:string,
}

export type Cashier = {
    code:string,
    name:string,
}

export type PaymentMethod = {
    number: number,
    name: string,
}

export type CustomerGroup = {
    code:string,
    name:string,
}

export type DiscountGroup = {
    code:string,
    name:string,
}

export type Terms = {
    id: number,
    i18n: I18n | null
}