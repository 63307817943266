import moment from "moment";

const firstDayOfPeriod = (period, periodType) => {
  //yyyy-mm-dd
  const mom = moment(period);
  switch (periodType) {
    case "Day":
      return mom;
    case "Week":
      return mom.startOf("isoWeek");
    case "Month":
      return mom.startOf("month");
    case "Quarter":
      return mom.startOf("quarter");
    case "QuarterHour":
      return mom;
    case "Hour":
      return mom;
    case "Year":
      return mom.startOf("year");
  }
  throw "Unexpected periodType " + periodType
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}


export const getDateInWeek = (weekStartDate) => {
  let start = moment(weekStartDate);
  let dates = [];
  dates.push(start.format('YYYY-MM-DD'));
  for (let i = 0; i < 6; i++) {
    const d = start.add(1, 'd');
    dates.push(d.format('YYYY-MM-DD'));
    start = d;
  }
  return dates;
}

export const getWeekDayFromDate = (date, doCapitalize, locale="en") => {
  const formatter = new Intl.DateTimeFormat(locale, {dateStyle: 'full'});
  const parts = formatter.formatToParts(date)
  if (parts.length > 0) {
    if (doCapitalize)
      return capitalize(parts[0].value); //weekday
    return parts[0].value
  }
  return "";
}

export const getWeeksOfMonth = (startDate, endDate) => {
  let weeks = [];
  let start = moment(startDate);
  let end = moment(endDate);
  
  while (start.isBefore(end)) {
    const startOfWeek = start.startOf('isoWeek').format('YYYY-MM-DD');
    const endOfWeek = start.endOf('isoWeek').format('YYYY-MM-DD');

    weeks.push({
      start: startOfWeek,
      end: endOfWeek,
      week: start.isoWeek()
    });
    start = start.add(1, 'w');
  }
  return weeks;
}

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  let dates = [];
  let currDate = moment(startDate).startOf('day');
  let lastDate = moment(endDate).startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }
  return dates;
}

export const getDayNames  = (locale = 'en', format = 'long') =>{
  const formatter = new Intl.DateTimeFormat(locale, { weekday: format, timeZone: 'UTC' });
  const days = [1, 2, 3, 4, 5, 6, 7].map(day => {
    const dd = day < 10 ? `0${day}` : day;
    return new Date(`2017-01-${dd}T00:00:00+00:00`);
  });
  return days.map(date => formatter.format(date));
}

export const getMonthNames = (locale = 'en', format = 'long') => {
  const formatter = new Intl.DateTimeFormat(locale, { month: format, timeZone: 'UTC' });
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
    const mm = month < 10 ? `0${month}` : month;
    return new Date(`2017-${mm}-01T00:00:00+00:00`);
  });
  return months.map(date => formatter.format(date));
}

export default firstDayOfPeriod;