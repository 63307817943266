import ShopStatuses from "./components/PickAndPay/widgets/ShopStatuses";
import MemberStats from "./components/PickAndPay/widgets/MemberStats";
import PostInstallTasks from "./components/PickAndPay/widgets/PostInstallTasks";
import {useUser} from "./UserContext";
import {RoleNames} from "./types";
import { t } from "i18next";

const PickAndPay = ()=>{
    const {user} = useUser();
    const isAdmin = user?.roles.includes(RoleNames.Administrator) ?? false;
  return(
        <div className={"pick-n-pay"}>
            <h1>{t('pick-n-pay.header')}</h1>
          <div className={"widgets"}>
            <ShopStatuses />
            {/*<MemberStats />*/}
          {isAdmin && (
            <PostInstallTasks />
          )}
          </div>
        </div>
    )
}

export default PickAndPay;