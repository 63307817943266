import {useEffect, useState} from "react";
import firstDayOfPeriod from "../../firstDayOfPeriod";
import moment from "moment";

export const useTerminalStatistics = (props) => {
  const [terminalStats, setTerminalStats] = useState([]);
  const [terminalTypeStats, setTerminalTypeStats] = useState({
    "Kiosker":{saleSum: 0, saleQty: 0, percentage:0},
    "Drive-In-kassor":{saleSum: 0, saleQty: 0, percentage:0},
    "Web":{saleSum: 0, saleQty: 0, percentage:0},
    "Övriga kassor":{saleSum: 0, saleQty: 0, percentage:0},
  });

  const [compareTerminalStats, setCompareTerminalStats] = useState([]);
  const [compareTerminalTypeStats, setCompareTerminalTypeStats] = useState({
    "Kiosker":{saleSum: 0, saleQty: 0, percentage:0},
    "Drive-In-kassor":{saleSum: 0, saleQty: 0, percentage:0},
    "Web":{saleSum: 0, saleQty: 0, percentage:0},
    "Övriga kassor":{saleSum: 0, saleQty: 0, percentage:0},
  });

  const [statTermStatus, setStatTermStatus] = useState({
    status: 'loading',
    compareStatus: 'done'
  });

  const fetchStats = () => {
    setStatTermStatus({status: 'loading', compareStatus: 'loading'});

    let formattedPeriod = firstDayOfPeriod(props.period, props.periodType).format("YYYYMMDD");
    let qs = `profitCenterId=${props.profitCenter?.code ?? ""}`;
    if (props.profitCenter == null && props.profitCenterArea != null) {
      qs = `&areaId=${props.profitCenterArea?.code ?? ""}`;
    }

    let url = ''
    if (props.period)
      url = `/api/terminal-sales?${qs}&periodType=${props.periodType}&period=${formattedPeriod}&count=${props.count}`
    else if (props.from && props.to) {
      const formattedFrom = moment(props.from).format("YYYYMMDD");
      const formattedTo = moment(props.to).format("YYYYMMDD");

      url = `/api/terminal-sales?${qs}&periodType=${props.periodType}&from=${formattedFrom}&to=${formattedTo}&count=${props.count}`;
    }

    if (url === '') {
      console.log('useTerminalStatistics: url is empty');
      setStatTermStatus({...statTermStatus, status: 'done', compareStatus: 'done'});
      return;
    }

    const useTerminalGroups = true;
    
    const promises = [];
    promises.push(fetch(url)
      .then(response => response.json())
      .then(result => {
        setTerminalStats(result.sales)
        const otherFilter = (s) => {
          return s.description.toLowerCase().indexOf("kiosk") === -1 &&
            s.description.toLowerCase().indexOf("drive") === -1 &&
            s.description.toLowerCase().indexOf("weborder") === -1;
        };
        const groups = result.sales.map(m => m.group).filter((v, i, a) => a.indexOf(v) === i);
        if (groups.length > 0 && groups.every(g=>g != null)) {
          const t = {};
          let totalSum = 0;
          groups.forEach(g => {
            t[g] = {
              saleSum: result.sales.filter(f => f.group === g).reduce((a, b) => a + b.saleSum, 0),
              saleQty: result.sales.filter(f => f.group === g).reduce((a, b) => a + b.saleQty, 0),
              customerQty: result.sales.filter(f => f.group === g).reduce((a, b) => a + b.customerQty, 0)
            };
            totalSum+=t[g].saleSum;
          });
          groups.forEach(g => {
            t[g].percentage = t[g].saleSum / totalSum;
          });
          setTerminalTypeStats(t);
          
        } else {
          const temp = {
            "Self servie": {
              saleSum: result.sales.filter(s => s.description.toLowerCase().indexOf("kiosk") > -1).reduce((a, b) => a + b.saleSum, 0),
              saleQty: result.sales.filter(s => s.description.toLowerCase().indexOf("kiosk") > -1).reduce((a, b) => a + b.saleQty, 0),
              customerQty: result.sales.filter(s => s.description.toLowerCase().indexOf("kiosk") > -1).reduce((a, b) => a + b.customerQty, 0),
            },
            "Drive-in": {
              saleSum: result.sales.filter(s => s.description.toLowerCase().indexOf("drive") > -1).reduce((a, b) => a + b.saleSum, 0),
              saleQty: result.sales.filter(s => s.description.toLowerCase().indexOf("drive") > -1).reduce((a, b) => a + b.saleQty, 0),
              customerQty: result.sales.filter(s => s.description.toLowerCase().indexOf("drive") > -1).reduce((a, b) => a + b.customerQty, 0)
            },
            "Web": {
              saleSum: result.sales.filter(s => s.description.toLowerCase().indexOf("weborder") > -1).reduce((a, b) => a + b.saleSum, 0),
              saleQty: result.sales.filter(s => s.description.toLowerCase().indexOf("weborder") > -1).reduce((a, b) => a + b.saleQty, 0),
              customerQty: result.sales.filter(s => s.description.toLowerCase().indexOf("weborder") > -1).reduce((a, b) => a + b.customerQty, 0)
            },
            "Other": {
              saleSum: result.sales.filter(otherFilter).reduce((a, b) => a + b.saleSum, 0),
              saleQty: result.sales.filter(otherFilter).reduce((a, b) => a + b.saleQty, 0),
              customerQty: result.sales.filter(otherFilter).reduce((a, b) => a + b.customerQty, 0)
            }
          };
          setTerminalTypeStats(temp);
          const sumALl = Object.values(temp).reduce((a, b) => a + b.saleSum, 0);
          Object.keys(temp).forEach(k => {
            temp[k].percentage = temp[k].saleSum / sumALl;
          });
        }
        setStatTermStatus({...statTermStatus, status: 'done'});
      }).catch(e => console.log(url)));

    if (props.compare !== null || (props.compareFrom !== null && props.compareTo !== null)) {
      //console.log('useTerminalStatistics: fetch compare');

      let compareUrl = '';
      if (props.compare) {
        let formattedCompareTo = firstDayOfPeriod(props.compare, props.periodType).format("YYYYMMDD");
        compareUrl = `/api/terminal-sales?${qs}&periodType=${props.periodType}&period=${formattedCompareTo}&count=${props.count}`;
      } else if (props.compareFrom && props.compareTo) {
        let formattedCompareFrom = firstDayOfPeriod(props.compareFrom, props.periodType).format("YYYYMMDD");
        let formattedCompareTo = firstDayOfPeriod(props.compareTo, props.periodType).format("YYYYMMDD");

        compareUrl = `/api/terminal-sales?${qs}&periodType=${props.periodType}&from=${formattedCompareFrom}&to=${formattedCompareTo}&count=${props.count}`;
      }

      if (compareUrl === '') {
        setCompareTerminalStats([]);
        setCompareTerminalTypeStats({});
        setStatTermStatus({...statTermStatus, compareStatus: 'done'});
        return;
      }

      promises.push(fetch(compareUrl)
        .then(response => response.json())
        .then(result => {
          setCompareTerminalStats(result.sales);
          const groups = result.sales.map(m => m.group).filter((v, i, a) => a.indexOf(v) === i);
          
          if (groups.length > 0) {
            const t = {};
            let totalSum = 0;
            groups.forEach(g => {
              t[g] = {
                saleSum: result.sales.filter(f => f.group === g).reduce((a, b) => a + b.saleSum, 0),
                saleQty: result.sales.filter(f => f.group === g).reduce((a, b) => a + b.saleQty, 0),
                customerQty: result.sales.filter(f => f.group === g).reduce((a, b) => a + b.customerQty, 0)
              };
              totalSum += t[g].saleSum;
            });
            groups.forEach(g => {
              t[g].percentage = t[g].saleSum / totalSum;
            });
            setCompareTerminalTypeStats(t);
          } else {
            const temp = {
              "Self service": {
                saleSum: result.sales.filter(s => s.description.toLowerCase().indexOf("kiosk") > -1).reduce((a, b) => a + b.saleSum, 0),
                saleQty: result.sales.filter(s => s.description.toLowerCase().indexOf("kiosk") > -1).reduce((a, b) => a + b.saleQty, 0),
                customerQty: result.sales.filter(s => s.description.toLowerCase().indexOf("kiosk") > -1).reduce((a, b) => a + b.customerQty, 0),
              },
              "Drive-in": {
                saleSum: result.sales.filter(s => s.description.toLowerCase().indexOf("drive") > -1).reduce((a, b) => a + b.saleSum, 0),
                saleQty: result.sales.filter(s => s.description.toLowerCase().indexOf("drive") > -1).reduce((a, b) => a + b.saleQty, 0),
                customerQty: result.sales.filter(s => s.description.toLowerCase().indexOf("drive") > -1)  .reduce((a, b) => a + b.customerQty, 0),
              },
              "Web": {
                saleSum: result.sales.filter(s => s.description.toLowerCase().indexOf("weborder") > -1).reduce((a, b) => a + b.saleSum, 0),
                saleQty: result.sales.filter(s => s.description.toLowerCase().indexOf("weborder") > -1).reduce((a, b) => a + b.saleQty, 0),
                customerQty: result.sales.filter(s => s.description.toLowerCase().indexOf("weborder") > -1).reduce((a, b) => a + b.customerQty, 0),
              },
              "Other": {
                saleSum: result.sales.filter(s => s.description.toLowerCase().indexOf("kassa") > -1).reduce((a, b) => a + b.saleSum, 0),
                saleQty: result.sales.filter(s => s.description.toLowerCase().indexOf("kassa") > -1).reduce((a, b) => a + b.saleQty, 0),
                customerQty: result.sales.filter(s => s.description.toLowerCase().indexOf("kassa") > -1).reduce((a, b) => a + b.customerQty, 0),
              }
            };
            const sumALl = Object.values(temp).reduce((a, b) => a + b.saleSum, 0);
            Object.keys(temp).forEach(k => {
              temp[k].percentage = temp[k].saleSum / sumALl;
            });
              
            setCompareTerminalTypeStats(temp);
          }
          setStatTermStatus({...statTermStatus, compareStatus: 'done'});
        }).catch(e => console.log(e)));
    } else {
      setCompareTerminalStats([]);
      setCompareTerminalTypeStats({});
      setStatTermStatus({...statTermStatus, compareStatus: 'done'});
    }

    Promise.all(promises).then(() => {

      setStatTermStatus({status: 'done', compareStatus: 'done'});
    });

  }
  useEffect(() => {
    fetchStats();
  }, [props.period, props.profitCenter, props.profitCenterArea, props.compare, props.from, props.to, props.compareFrom, props.compareTo]);
  return {terminalStats, compareTerminalStats, terminalTypeStats, compareTerminalTypeStats, statTermStatus};
}
