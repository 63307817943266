import React, {useState} from "react";
import moment from "moment";
import TerminalSalesList from "../TerminalSalesList";
import {useStatistics} from "./hooks/useStatistics";
import StatisticValue from "../StatisticValue";
import {useTerminalStatistics} from "./hooks/useTerminalStatistics";
import {useArticleStatistics} from "./hooks/useArticleStatistics";
import Loading from "../Loading";
import SalesBarChart from "./SalesBarChart";
import {ArticleGroupList} from "./ArticleGroupList";
import {useTranslation} from "react-i18next";

const DailyDashboard = (props) => {
  //useTraceUpdate(props, 'DailyDashboard');
  const {t} = useTranslation();
  const {settings, profitCenter, profitCenterArea, compareTo} = props;
  const { stats, compareStats, statStatus} = useStatistics({periodType: "Day", period: props.period, profitCenter, profitCenterArea, compare:props.compareTo });
  const [ stats1, compareStats1, statStatus1] = useStatistics({returnArray:true, periodType: "QuarterHour", period: props.period, profitCenter, profitCenterArea, compare:props.compareTo });
  const [ stats2, compareStats2, statStatus2] = useStatistics({returnArray:true, periodType: "Hour", period: props.period, profitCenter, profitCenterArea, compare:props.compareTo });
  
  const { terminalStats, terminalTypeStats, compareTerminalStats, compareTerminalTypeStats, statTermStatus} = useTerminalStatistics({ periodType: "Day", period: props.period, profitCenter, profitCenterArea, compare:props.compareTo });
  const { articleStats, articleCompareStats,articleStatStatus, getArticleStatsInGroup} = useArticleStatistics({ 
    periodType: "Day", 
    period: props.period, 
    profitCenter, 
    profitCenterArea, 
    compare:props.compareTo 
  });
  
  const displayDate = new Intl.DateTimeFormat(settings.locale, { dateStyle: 'full' }).format(moment(props.period).toDate());
  
  const statuses = [statStatus, statTermStatus, articleStatStatus, statStatus1];
  const loading =  statuses.some(s=>s.status === 'loading' || s.compareStatus === 'loading');   
  
  return (
      <>
          <div className="tab-panel-header">
            
            <h2 className={"mt-3 text-center"} style={{textTransform: "capitalize"}}>
              {profitCenterArea && !profitCenter && (
                  <span>{profitCenterArea.description} - </span>
              )}
              {profitCenter && (
                  <span>{profitCenter.name} - </span>
              )}
              <span>{displayDate}</span>
              <Loading visible={loading} />
            </h2>
            
            <div className={"stats-summary"}>
              {stats.length > 0 && (
                  <>
                    <StatisticValue className={"m-auto"} text={t('sales.common.net-sales')}         value={stats[0].saleTax0Sum}    compareValue={compareStats[0]?.saleTax0Sum}/>
                    <StatisticValue className={"m-auto"} text={t('sales.common.gross-sales')}       value={stats[0].saleSum}        compareValue={compareStats[0]?.saleSum}/>
                    <StatisticValue className={"m-auto"} text={t('sales.common.purchases')}         value={stats[0].customerQty}    compareValue={compareStats[0]?.customerQty} formatter={'other'} />
                    <StatisticValue className={"m-auto"} text={t('sales.common.profit')}            value={stats[0].profitSum}      compareValue={compareStats[0]?.profitSum}/>
                    <StatisticValue className={"m-auto"} text={t('sales.common.profit-percentage')} value={stats[0].profitPercent}  compareValue={compareStats[0]?.profitPercent} formatter={'percent'} />
                    <StatisticValue className={"m-auto"} text={t('sales.common.average-amount')}    value={stats[0].saleSum / stats[0].customerQty}  compareValue={compareStats[0]?.saleSum / compareStats[0]?.customerQty}/>
                    <StatisticValue className={"m-auto"} text={t('sales.common.discounts')}         value={stats[0].discSum}        compareValue={compareStats[0]?.discSum}/>
                  </>
              )}
            </div>
          </div>
          <div className="tab-panel-content">
            <div className="tab-content-wrapper">

              {settings.quarterHourSales && (
              <div className={"chart"}>
                <SalesBarChart 
                  keyField={"periodTime"} 
                  sales={stats1}
                  title={t('sales.daily.sales-during-day')} 
                  valueKey={'saleTax0Sum'}
                  compare={compareStats1}
                  keyFormatter={(key)=>`${key.slice(0,2)}:${key.slice(2)}`}
                />
              </div>
              )}
              
              {settings.quarterHourPurchases && (
              <div className={"chart"}>
                <SalesBarChart
                  keyField={"periodTime"}
                  sales={stats1}
                  title={t('sales.daily.sales-count-during-day')}
                  displayUnits={true}
                  valueKey={'saleQty'}
                  compare={compareStats1}
                  keyFormatter={(key)=>`${key.slice(0,2)}:${key.slice(2)}`}
                />
              </div>
              )}
            
                {settings.hourlySales && (
                <div className={"chart"}>
                  <SalesBarChart
                    keyField={"periodTime"}
                    sales={stats2}
                    title={t('sales.daily.sales-during-day')}
                    valueKey={'saleTax0Sum'}
                    compare={compareStats2}
                    keyFormatter={(key)=>`${key.slice(0,2)}:${key.slice(2)}`}
                  />
                </div>
                )}
                {settings.hourlyPurchases && (
                <div className={"chart"}>
                  <SalesBarChart
                    keyField={"periodTime"}
                    sales={stats2}
                    title={t('sales.daily.sales-count-during-day')}
                    displayUnits={true}
                    valueKey={'saleQty'}
                    compare={compareStats2}
                    keyFormatter={(key)=>`${key.slice(0,2)}:${key.slice(2)}`}
                  />
                </div>
                )}
              
              <div className={"group-sales-lists"}>
                  <ArticleGroupList title={t('sales.common.article-groups')}
                                    sales={articleStats}
                                    compareStats={articleCompareStats}
                                    expand={getArticleStatsInGroup}
                  />

                  <TerminalSalesList showTerminalList={props.profitCenter != null}
                                    stats={terminalStats}
                                    typeStats={terminalTypeStats}
                                    compareStats={compareTerminalStats}
                                    compareTypeStats={compareTerminalTypeStats}
                  />

              </div>
          </div>
        </div>
      </>
  )
}

export default DailyDashboard;

