import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {NavLink} from "reactstrap";
import {Link, Outlet} from "react-router-dom";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useDebounce} from "use-debounce";
import Loading from "./Loading";
import {PaginatedResult, User} from "../types";
import {useTranslation} from "react-i18next";


interface State {
  query:string,
  users: PaginatedResult<User>
  loading: boolean;
}

const Users = () => {
  const debounceDelay = 200;
  const [state, setState] = useState<State>({
    query: '',
    users: {
      items: []
    },
    loading: false
  });
  
  const [debouncedQuery] = useDebounce(state.query, debounceDelay);
  
  const pageIndex = 0;
  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setState({
      ...state,
      [name]: value
    });
  }
  
  const onSubmit = (event:FormEvent)=>{
    event.preventDefault();
    fetchData();
  }
  
  const onClear = ()=>{
    setState({
      ...state,
      query: ''
    });
  }

  const fetchData = () => {
    setState({...state, loading:true});
    fetch(`/api/users?query=${encodeURIComponent(state.query)}&pageIndex=${pageIndex}&pageSize=5000`)
      .then(response => response.json())
      .then(users => {
        setState({
            ...state,
          users: users,
          loading:false
        })
      });
  }
  
  useEffect(()=>{
    fetchData();
  },[debouncedQuery])

  return (
      <div className='admin-page'>
        <div className={"users"}>
          <div className={'user-header'}>
            <h2>Hantera användare</h2>
            <Outlet />
            <div className={"create-user"}>
              <NavLink tag={Link} className="btn btn-add-user btn-primary" to="create">Ny användare</NavLink>
            </div>
          </div>
          <div className={"user-list"}>
            <Form className={"search"} onSubmit={onSubmit}>
              <div className={"input-group"}>
                <label className={"form-label input-group-text"}>Sök</label>
                <input className={"form-control"} placeholder={"Filtrera användare"} type={"search"} name={"query"}  value={state.query} onChange={handleChange}/>
                <Button type={"submit"}>Sök</Button>
                <Button variant={"secondary"}  onClick={onClear}>Rensa</Button>
              </div>
            </Form>
            <Loading visible={state.loading}/>
            {!state.loading && (
            <ul className={"user-list list"}>
              {state.users.items.map(user => {
                  return (<UserRow key={user.id} user={user}/>)
                }
              )}
            </ul>
            )}
          </div>
          
        </div>
      </div>
  )
}

interface UserRowProps {
  user: User
}
const UserRow = (props:UserRowProps) => {
  const {user} = props;
  const {t} = useTranslation();

  return (
      <li className={"user-list-item list-item"}>
        <article className='user-item'>
            <div className='user'>
              <span className={"user-name"}>{user.firstName} {user.lastName}</span>
              <span className='user-email'>{user.email}</span>
            </div>
            
          <ul className={"user-roles"}>
            {user.roles.map(role => (
            <li key={role}>
              <span className={"badge primary"}>
                {t(`users.roles.${role}`)}
              </span>
            </li>
            ))}
          </ul>
            <ul className={"profitcenters"}>
              {user.profitCenters.map(pc => {
                return (<li key={pc.code} className={"badge"}>{pc.name}</li>)
              })}
            </ul>
            <Link to={"/admin/users/" + user.id} className={"btn btn-edit"}>Ändra</Link>
        </article>
      </li>

  )
}


export default Users;