import {useEffect, useState} from "react";

export const usePnpLocales = () => {
   const [locales, setLocales] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        setLoading(true);
        fetch('api/pick-n-pay/settings')
            .then(response => response.json())
            .then((data)=>{
                setLocales(data.enabledLanguages    );
                setLoading(false);
            });
    }, []);
    
    return { locales, loading};
};