import React from "react";
import moment from "moment";
import SdptSalesList from "../SdptSalesList";
import TerminalSalesList from "../TerminalSalesList";
import YearMonthsBarChart from "../YearMonthsBarChart";
import {useStatistics} from "./hooks/useStatistics";
import StatisticValue from "../StatisticValue";
import {useTerminalStatistics} from "./hooks/useTerminalStatistics";
import {useArticleStatistics} from "./hooks/useArticleStatistics";
import Loading from "../Loading";
import {useRangeStatistics} from "./hooks/useRangeStatistics";
import SalesBarChart from "./SalesBarChart";
import {ArticleGroupList} from "./ArticleGroupList";
import {useTranslation} from "react-i18next";
import {DateTime} from "luxon";
import {useSettings} from "../../SettingsContext";
import {capitalize} from "../firstDayOfPeriod";

const YearlyDashboard = (props) => {
  const year = moment(props.period).year();
  const monthsStarts = [];
  for (let i = 1; i < 13; i++) {
    monthsStarts.push(`${year}-${i.toString().padStart(2, '0')}-01`);
  }
  const {settings} = useSettings();
  const compareYear = props.compareTo ? moment(props.compareTo).year() : null;
  
  const {profitCenter, profitCenterArea} = props;
  const {stats, compareStats, statStatus} = useStatistics({periodType: "Year", period: props.period, profitCenter, profitCenterArea, compare:props.compareTo});
  const { terminalStats, terminalTypeStats, statTermStatus, compareTerminalStats, compareTerminalTypeStats} = useTerminalStatistics({ periodType: "Year", period: props.period, profitCenter, profitCenterArea, compare:props.compareTo, count:100 });
  const { articleStats, articleCompareStats, articleStatStatus, getArticleStatsInGroup} = useArticleStatistics({ periodType: "Year", period: props.period, profitCenter, profitCenterArea, compare:props.compareTo, count:100 });
  const [ rangeStats, rangeCompareStats, rangeStatus ] = useRangeStatistics({
    returnArray: true,
    periodType:'Month',
    from:`${year}-01-01`,
    to:`${year}-12-31`,
    compareFrom:compareYear ? `${compareYear}-01-01` : null,
    compareTo:compareYear ? `${compareYear}-12-31` : null,
    profitCenter,
    profitCenterArea
  });
  const {t} = useTranslation();

  const statuses = [statStatus, statTermStatus, articleStatStatus, rangeStatus];
  const loading =  statuses.some(s=>s.status === 'loading' || s.compareStatus === 'loading');

  return (
      <>
        <div className="tab-panel-header">
          
          <h2 className={"mt-3 text-center"}>
            {profitCenterArea && !profitCenter && (
                <span>{profitCenterArea.description} - </span>
            )}
            {profitCenter && (
                <span>{profitCenter.name} - </span>
            )}
            <span>{t('sales.yearly.header', {yearName: year})}</span>
            <Loading visible={loading} />
          </h2>
          <div className={"stats-summary stats-summary-year"}>
            {stats.length > 0 && (
                <>
                  <StatisticValue className={"m-auto"} text={t('sales.common.net-sales')}         value={stats[0].saleTax0Sum} compareValue={compareStats[0]?.saleTax0Sum}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.gross-sales')}       value={stats[0].saleSum} compareValue={compareStats[0]?.saleSum}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.purchases')}         value={stats[0].customerQty} formatter={'other'} compareValue={compareStats[0]?.customerQty}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.profit')}            value={stats[0].profitSum} compareValue={compareStats[0]?.profitSum}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.profit-percentage')} value={stats[0].profitPercent} formatter={'percent'} compareValue={compareStats[0]?.profitPercent}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.average-amount')}    value={stats[0].saleSum / stats[0].customerQty}  compareValue={compareStats[0]?.saleSum / compareStats[0]?.customerQty}/>
                  <StatisticValue className={"m-auto"} text={t('sales.common.discounts')}         value={stats[0].discSum} compareValue={compareStats[0]?.discSum}/>
                </>
            )}
          </div>
        </div>
        <div className="tab-panel-content">
          <div className="tab-content-wrapper">
            <div className={"chart"}>
              <SalesBarChart
                keyField={"periodId"}
                sales={rangeStats}
                compare={rangeCompareStats}
                title={t('sales.yearly.sales-count-per-month')}
                valueKey={'saleTax0Sum'}
                keyFormatter={(key)=>capitalize(DateTime.fromFormat(key.toString(),'yyyyMMdd').toFormat('MMM', { locale: settings.locale }))}
              />
            </div>
      
            <div className={"chart"}>
              <SalesBarChart
                keyField={"periodId"}
                sales={rangeStats}
                compare={rangeCompareStats}
                displayUnits={true}
                title={t('sales.yearly.sales-per-month')}
                valueKey={'customerQty'}
                keyFormatter={(key)=>capitalize(DateTime.fromFormat(key.toString(),'yyyyMMdd').toFormat('MMM', { locale: settings.locale }))}
              />
            </div>
          <div className={"group-sales-lists"}>
              <ArticleGroupList title={t('sales.common.article-groups')}
                                sales={articleStats}
                                compareStats={articleCompareStats}
                                expand={getArticleStatsInGroup}
              />
              <TerminalSalesList
                                stats={terminalStats}
                                typeStats={terminalTypeStats}
                                showTerminalList={props.profitCenter != null}
                                compareStats={compareTerminalStats}
                                compareTypeStats={compareTerminalTypeStats}
              />
            </div>
          </div>
        </div>
      </>
  )
}

export default YearlyDashboard;