import React, {useState} from 'react';
import {Tab, Tabs} from "react-bootstrap";
import {useUser} from "../UserContext";
import DailyDashboard from "./Dashboard/Daily";
import MonthlyDashboard from "./Dashboard/Monthly";
import YearlyDashboard from "./Dashboard/Year";
import Login from "./Login";
import {useNavigate} from "react-router-dom";
import WeeklyDashboard from "./Dashboard/Weekly";
import moment from "moment";
import ProfitCenterPicker from "./ProfitCenterPicker";
import ProfitCenterAreaPicker from "./ProfitCenterAreaPicker";
import PeriodSelector from "./PeriodSelector";
import RangeDashboard from "./Dashboard/Range";
import {useDebounce} from "use-debounce";
import {useSettings} from "../SettingsContext";
import {useTranslation} from "react-i18next";

const Home = () => {
  useNavigate();
  const {t} = useTranslation();
  const { user } = useUser();
  const { settings } = useSettings();
  const [state, setState] = useState({ 
    period: moment().subtract(1,'days').format("YYYY-MM-DD"),
    periodFrom:moment().add(5,'days').format('YYYY-MM-DD'), 
    profitCenter: null, area:null,
    filteredProfitCenters: user?.profitCenters ?? null,
    rangeActive: false,
    compareTo: '',
    compareRangeFrom: '',
    compareRangeTo:''
  });
  
  const debounceDelay = 500;
  
  const [debouncedPeriod] = useDebounce(state.period, debounceDelay);
  const [debouncedPeriodTo] = useDebounce(state.periodTo, debounceDelay);
  const [debouncedCompareTo] = useDebounce(state.compareTo, debounceDelay);
  const [debouncedCompareRangeFrom] = useDebounce(state.compareRangeFrom, debounceDelay);
  const [debouncedCompareRangeTo] = useDebounce(state.compareRangeTo, debounceDelay);

  if(!user){     
    return (<Login/>)
  }
  
  if(user.profitCenters.length === 0){
    return (
        <div className={"alert alert-warning w-50"} role="alert">
          <h4 className={"alert-heading"}>{t('sales.home.header')}</h4>
          <p>{t('sales.home.no-profitcenter-access')}</p>
        </div>
    );
  }

  const hasAreaAccess = user.areas?.length > 0;
  
  const onPeriodChanged = (from,to) => {
      setState({
        ...state,
        period: from,
        periodTo: to,
      });  
  }
  
  const onShowRangeChanged = (rangeActive)=>{
    setState({
      ...state,
      rangeActive: rangeActive
    })
  }
  
  const onCompareToChanged = (newValue)=>{
    setState({
      ...state,
      compareTo: newValue
    });
  };
  
  const onCompareActiveChanged = (newValue)=>{
    if(!newValue){
      setState({
        ...state,
        compareTo: '',
        compareRangeFrom: '',
        compareRangeTo: ''
      });
    }
  }
  
  const onCompareRangeChanged = (compareRange)=>{
    if(compareRange){
      setState({
        ...state,
        compareRangeFrom: compareRange.compareRangeFrom,
        compareRangeTo: compareRange.compareRangeTo
      })
    }else {
      setState({
        ...state,
        compareRangeFrom: '',
        compareRangeTo: ''
      })
    }
  }
  
  const selectProfitCenter  = (profitCenter) =>{
    setState({
      ...state,
      profitCenter: profitCenter
    });
    return profitCenter;
  }
  
  const selectProfitCenterArea = (area)=>{
    setState({
      ...state,
        area: area,
        profitCenter: null,
        filteredProfitCenters: area? user.profitCenters.filter(p => p.area === area.code): user.profitCenters
    });
  }
    
  const getProfitCenter = ()=>{
    if(state.profitCenter != null)
      return state.profitCenter;     
    else if (!hasAreaAccess) {
      return selectProfitCenter(user.profitCenters[0]);
    }
    return null;
  }

  const getProfitCenterArea = ()=>{
    if(state.area != null)
      return state.area;
    return null;
  }
  const weekNumber = moment(state.period).isoWeek();
  return (
      <div>
        <div className='filter-container'>
          <div className='filters'>
            {hasAreaAccess && (
              <ProfitCenterAreaPicker profitCenterAreas={user.areas} 
                                      onProfitCenterAreaSelected={selectProfitCenterArea} 
              />
            )}
            
            <ProfitCenterPicker showAllOption={hasAreaAccess} 
                                profitCenters={state.filteredProfitCenters ?? user.profitCenters} 
                                selectedProfitCenter={getProfitCenter()} 
                                onProfitCenterSelected={selectProfitCenter} 
            />
            
            <PeriodSelector initialFrom={state.period} initialTo={state.periodTo} showWeekNumber={true} 
                            onPeriodChanged={onPeriodChanged} 
                            onShowRangeChanged={onShowRangeChanged} 
                            onCompareToChanged={onCompareToChanged}
                            onCompareRangeChanged={onCompareRangeChanged}
                            onCompareActiveChanged={onCompareActiveChanged}
                            
              />
            </div>
          </div>
          {!state.rangeActive && (
            <div className='tabs-container'>
              <Tabs defaultActiveKey={"daily"}>
                <Tab eventKey={"daily"} title={t('sales.daily.tab-name')}>
                  <DailyDashboard settings={settings.daily}
                                  period={debouncedPeriod} 
                                  profitCenter={getProfitCenter()} 
                                  profitCenterArea={getProfitCenterArea()} 
                                  compareTo={debouncedCompareTo}
                  />
                </Tab>
                <Tab eventKey={"weekly"} title={t('sales.weekly.tab-name',{weekNumber})}>
                  <WeeklyDashboard period={debouncedPeriod} 
                                  profitCenter={getProfitCenter()} 
                                  profitCenterArea={getProfitCenterArea()} 
                                  compareTo={debouncedCompareTo} 
                  />
                </Tab>          
                <Tab eventKey={"monthly"} title={t('sales.monthly.tab-name')}>
                  <MonthlyDashboard period={debouncedPeriod} 
                                    profitCenter={getProfitCenter()} 
                                    profitCenterArea={getProfitCenterArea()} 
                                    compareTo={debouncedCompareTo} 
                  />
                </Tab>
                <Tab eventKey={"year"} title={t('sales.yearly.tab-name')}>
                  <YearlyDashboard period={debouncedPeriod} 
                                  profitCenter={getProfitCenter()} 
                                  profitCenterArea={getProfitCenterArea()} 
                                  compareTo={debouncedCompareTo} 
                  />
                </Tab>
              </Tabs>
            </div>
          )}
        
        {state.rangeActive && (
          <div className='tabs-container'>
            <RangeDashboard
                periodFrom={debouncedPeriod}
                periodTo={debouncedPeriodTo}
                compareFrom={debouncedCompareRangeFrom}
                compareTo= {debouncedCompareRangeTo}
                profitCenter={getProfitCenter()} 
                profitCenterArea={getProfitCenterArea()} 
            />
          </div>
        )}
       
      </div>
  );
}
export default Home;

