import React, {useState} from "react";
import {Accordion} from "react-bootstrap";
import Loading from "../Loading";
import {ChevronDown, ChevronUp} from "react-bootstrap-icons";
import {useSettings} from "../../SettingsContext";
import {useFormatters} from "../formatters";
import { t } from "i18next";

export const ArticleGroupList = (props) => {
  const {settings} = useSettings();
  const formatters  = useFormatters(settings);
  const {sales, title, expand, compareStats} = props;
  const [expandedData, setExpandedData] = useState([]);
  const [expandedRowId, setExpandedRowId] = useState(null); // [rowId, setRowId
  const [loading, setLoading] = useState(false);
  const findCompareRow= (code) => {
    return compareStats.find(s => s.code?.trim() === code?.trim());
  };
  
  const expandGroup = (code) => {
    if(!sales)
      return;
    
    let groupToExpand = sales.find(x => x.code === code);
    if (groupToExpand) {
      setLoading(true);
      expand(groupToExpand).then(data => {
        setExpandedRowId(code);
        setExpandedData(data.sales);
        setLoading(false);
      });
    }
  };
  
  const diff = (a, b) => {
    return a - b;
  }
  
  const getClassName = (calcValue) => {
    if(calcValue  === 0){
      return "text-secondary"
    }
    if(calcValue > 0)
      return "text-success";
    return "text-danger";
  };
  
  const compareMode = compareStats && compareStats.length > 0;

  const renderRow = (row)=> {
    const compareRow = compareMode?  findCompareRow(row.code):null;
    return (
    <Accordion.Item eventKey={row.code} key={row.code}>
      <Accordion.Header>
          <span className={"description"}>{row.description}</span>
          <div className={"item-sum"}>
            <span className={"total-value"}>{formatters.currency.format(row.saleSum)}</span>
            {compareRow && (
              <span className={"compare-value " + getClassName(diff(row.saleSum, compareRow?.saleSum)) }>
                {diff(row.saleSum, compareRow.saleSum) > 0 && (<><ChevronUp />+</>)}
                {diff(row.saleSum, compareRow.saleSum) < 0 && (<><ChevronDown /></>)}
                {formatters.currency.format(diff(row.saleSum, compareRow.saleSum))}
              </span>
            )}
          </div>
          
          <div className={"item-qty"}>
            <span className={"total-qty"}>
              {formatters.number.format(row.saleQty)}
            </span>
            {compareRow && (
            <span className={"compare-qty " + getClassName(diff(row.saleSum, compareRow?.saleSum)) }>
              {diff(row.saleQty, compareRow.saleQty) > 0 && (<><ChevronUp />+</>)}
              {diff(row.saleQty, compareRow.saleQty) < 0 && (<><ChevronDown /></>)}
              {formatters.number.format(diff(row.saleQty, compareRow.saleQty))}
            </span>
            )}
          </div>
      </Accordion.Header>
      <Accordion.Body>
        <Loading visible={loading}/>
        {!loading && (
          <>
            <h3>{t('sales.common.bestselling-articles')}</h3>
            <ol className={"list-group bestselling-articles"}>
              {(expandedRowId === row.code) && expandedData.map((d) => (
                <li key={d.code} className={"list-group-item"}>
                  <span>{d.description}</span>
                  <div className={"list-group-values"}>
                    <span className={"value value-qty badge primary"}>{formatters.number.format(d.saleQty)}</span>
                    <span className={"value value-sum"}>{formatters.currency.format(d.saleSum)}</span>
                  </div>
                </li>
              ))}
            </ol>
          </>
        )}

      </Accordion.Body>
    </Accordion.Item>
  )};
  
  return (
    <div className={"article-group-list"}>
      <h3>{title}</h3>
      <Accordion onSelect={expandGroup}>
        {sales.map((row) => renderRow(row))}
      </Accordion>
    </div>
  );

};