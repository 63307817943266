import React, {useState} from 'react';
import {useUser} from "../UserContext";
import {FormControl, FormLabel} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Link, useNavigate} from "react-router-dom";

const Login = () => {
  const [state, setState] = useState({email: "", password: ""});
  const [showFail, setShowFail] = useState(false);
  const {onLogin} = useUser();
  const navigate = useNavigate();

  const submit = (event) => {
    event.preventDefault();
    onLogin(state.email, state.password)
        .then(success => {
          if (success)
            navigate('/');
          else{
            setShowFail(true);
          }
        })
  }

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value
    const name = target.name;

    setState({
          ...state,
          [name]: value
        }
    );
  }

  return (
      <>
        <div className={"login"}> 
          <form onSubmit={submit}>
            <h3>Logga in</h3>
            
            <div className={"e-mail-input"}>
              <FormLabel htmlFor={"email"}>E-post</FormLabel>
              <FormControl id={"email"} name={"email"} type={"email"} value={state.email} onChange={handleChange}/>
              
            </div>
            
            <div className={"password-input"}>
              <label htmlFor={"password"}>Lösenord</label>
              <FormControl id={"password"} name={"password"} type={"password"} value={state.password} onChange={handleChange}/>
              
            </div>

            {showFail &&(
                <div className={"alert alert-danger"} role="alert">
                  <p>Användarkontot saknades, eller så skrev du inte in rätt lösenord, försök igen.</p>
                </div>
            )}
            <div className={'login-footer'}>
              <Link className={"reset-password"} to={"/reset-password"}>Återställ lösenord</Link>
              <Button className={"btn"} type={"submit"} onClick={submit}>Logga in</Button>
            </div>
          </form>
        </div>
      </>
  );
}
export default Login;