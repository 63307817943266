import React, {useState} from 'react';
import {Collapse, Nav, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import {Link} from 'react-router-dom';
import {useUser} from "../UserContext";
import Button from "react-bootstrap/Button";
import {useSettings} from "../SettingsContext";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import LocaleSelector from "./PickAndPay/LocaleSelector";
import {RoleNames} from "../types";

const NavMenu = () => {
  const [state, setState] = useState({collapsed: true});
  const {user, onLogout} = useUser();
  const {settings} = useSettings();

  const {t, i18n } = useTranslation();
   
  const toggleNavbar = () => {
    setState({
      collapsed: !state.collapsed
    });
  }

  const isActive = (path) => {
    return window.location.pathname === path;
  };

  const isChildActive = (path) => {
    return window.location.pathname.startsWith(path);
  };

  const onLocaleChanged = (locale)=>{
    i18n.changeLanguage(locale).then(()=>{
      localStorage.setItem("locale", locale)
      settings.locale = locale;
      console.log("Language changed to: " + locale);
    });
  }
  
  let logout = (<NavItem>
                  <button className={'btn btn-logout'} onClick={()=>onLogout()}>{t('nav.logout')}</button>
                </NavItem>);
  let login = (<NavItem active={isActive("/login")}>
                        <NavLink tag={Link} className="text-white" to="/login">{t('nav.login')}</NavLink>
                      </NavItem>);
  
  let profile = (<NavItem className="text-white" to="/profile" active={isActive("/profile")}>
                            <NavLink tag={Link} className="text-white" to="/profile">{t('nav.profile')}</NavLink>
                          </NavItem>);
  
  let isAdmin = (user && user.roles.includes(RoleNames.Administrator));
  let showPrices = (user && user.roles.includes(RoleNames.PriceListManager) && settings.showPriceListInterface);
  let showWebOrder = (user && user.roles.includes(RoleNames.PickAndPayManager) && settings.showWebOrderInterface);
  
  return (
      <aside>
        <div className={(state.collapsed ? 'navbar hidden' : 'navbar show')}>
          <div className={'navbar-header'}>
            <NavbarBrand tag={Link} to="/">
              <img className={"logo"} src={"wpd-logo.svg"} alt={"Winpos dashboard logotype"}/>
              <img className={"icon"} src={"wpd-icon.svg"} alt={"Winpos dashboard logotype"}/>               
            </NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} className={(!state.collapsed ? 'show' : 'hidden')}>
            <svg width="50" height="50" viewBox="0 0 100 100">
              <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
              <path className="line line2" d="M 20,50 H 80" />
              <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
            </svg>
            </NavbarToggler>
          </div>
          <div className='nav-container' >
            <Nav className="navbar-nav" >
              <NavItem active={isActive("/")}>
                <NavLink tag={Link} to="/">{t('nav.home')}</NavLink>
              </NavItem>
              
              {(settings && settings.showPriceListInterface && showPrices) && (
                  <NavItem active={isActive("/prices")}>
                    <NavLink tag={Link} to="/prices">{t('nav.prices')}</NavLink>
                  </NavItem>
              )}
              
              {(settings && settings.showWebOrderInterface && showWebOrder) && (
                <NavItem active={isActive("/pick-n-pay")} className={isChildActive("/pick-n-pay")?"child-active":""}>
                  <NavLink tag={Link} to="/pick-n-pay">{t('nav.pick-n-pay.pick-n-pay')}</NavLink>
                  <Nav className={"navbar-nav"}>
                    <NavItem active={isActive("/pick-n-pay/shops")}>
                      <Link to={"pick-n-pay/shops"} className={"nav-link"}>{t('nav.pick-n-pay.shops')}</Link>
                    </NavItem>
                    <NavItem active={isActive("/pick-n-pay/campaigns")}>
                      <Link to={"pick-n-pay/campaigns"} className={"nav-link"}>{t('nav.pick-n-pay.campaigns')}</Link>
                    </NavItem>
                    <NavItem active={isActive("/pick-n-pay/stickers")}>
                      <Link to={"pick-n-pay/stickers"} className={"nav-link"}>{t('nav.pick-n-pay.stickers')}</Link>
                    </NavItem>
                    {isAdmin && (
                      <>
                      <NavItem active={isActive("/pick-n-pay/terms")}>
                        <Link to={"pick-n-pay/terms"} className={"nav-link"}>{t('nav.pick-n-pay.terms')}</Link>
                      </NavItem>
                      <NavItem active={isActive("/pick-n-pay/settings")} className={isChildActive("/pick-n-pay/settings")?"child-active":""}>
                        <Link to={"pick-n-pay/settings"} className={"nav-link"}>{t('nav.pick-n-pay.settings')}</Link>
                        <Nav className={"navbar-nav"}>
                          <NavItem active={isActive("/pick-n-pay/settings/payment-providers")}>
                            <Link to={"/pick-n-pay/settings/payment-providers"} className={"nav-link"}>{t('nav.pick-n-pay.payment-providers')}</Link>
                          </NavItem>
                        </Nav>
                      </NavItem>
                      
                      </>
                    )}
                  </Nav>
                </NavItem>
              )}
              
              {isAdmin &&
                  <>
                  <NavItem active={isActive("/admin/users")}>
                    <NavLink tag={Link} to="/admin/users">{t('nav.users')}</NavLink>
                  </NavItem>
                  </>
              }
              {user != null && profile }
              {user != null && logout }
              {user ==null && login }
            </Nav>
            <LocaleSelector locale={settings.locale} onChange={onLocaleChanged} />
            
            
          </div>
          <span className={"version"}>Version: {settings.version}</span>
        </div>
        
      </aside>
      
  );
}
export default NavMenu;
