import {PaymentProviderUsage} from "../types";
import React from "react";

const ProviderUsage = (props: { usage: PaymentProviderUsage[] }) => {

    return (<ul className={"provider-usage"}>
        {props.usage.map(usage => (
            <li className={"badge"} key={usage.shopId}>{usage.shopName}</li>
        ))}
    </ul>)
}
export default ProviderUsage;
