import {useEffect, useState} from "react";
import firstDayOfPeriod from "../../firstDayOfPeriod";

export const useStatistics = (props) => {
  const [stats, setStats] = useState([]);
  const [compareStats, setCompareStats] = useState([]);
  
  const [statStatus, setStatStatus] = useState({
    status: 'loading',
    compareStatus: 'loading'
  });
  
  const fetchStats = () => {
    setStatStatus({status: 'loading', compareStatus: 'loading'});
    let formattedPeriod = firstDayOfPeriod(props.period, props.periodType).format("YYYYMMDD");
    let qs = `profitCenterId=${props.profitCenter?.code ?? ""}`;
    if (props.profitCenter == null && props.profitCenterArea != null) {
      qs = `&areaId=${props.profitCenterArea?.code ?? ""}`;
    }

    const promises = [];
    promises.push(fetch(`/api/sales?${qs}&periodType=${props.periodType}&period=${formattedPeriod}`)
        .then(response => response.json())
        .then((data) => {
          setStats(data.sales);
          setStatStatus({...statStatus, status: 'done'})
        }));
    
    if(props.compare){
      let formattedCompareTo = firstDayOfPeriod(props.compare, props.periodType).format("YYYYMMDD");
      promises.push(fetch(`/api/sales?${qs}&periodType=${props.periodType}&period=${formattedCompareTo}`)
        .then(response => response.json())
        .then((data) => {
          setCompareStats(data.sales)
          setStatStatus({...statStatus, compareStatus: 'done'})
        }));
    } else {
      setCompareStats([]);
      setStatStatus({...statStatus, compareStatus: 'done'})
    }
    
    Promise.all(promises).then(()=>{
      setStatStatus({ status: 'done', compareStatus: 'done'});
    });
    
    
  }
  
  useEffect(() => {
    fetchStats();
  },[props.returnArray, props.period, props.profitCenter, props.profitCenterArea, props.compare]);
  
  
  if(props.returnArray){
    return [stats, compareStats, statStatus];
  }
  return { stats, compareStats, statStatus};
}
