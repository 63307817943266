import {useEffect, useState} from "react";
import firstDayOfPeriod from "../../firstDayOfPeriod";
import moment from "moment";

export const useRangeStatistics = (props) => {
  const [stats, setStats] = useState([]);
  const [compareStats, setCompareStats] = useState([]);

  const [statStatus, setStatStatus] = useState({
    status: 'loading',
    compareStatus: 'loading'
  });

  const fetchStats = () => {
    setStatStatus({status: 'loading', compareStatus: 'loading'});

    let qs = `profitCenterId=${props.profitCenter?.code ?? ""}`;
    if (props.profitCenter == null && props.profitCenterArea != null) {
      qs = `areaId=${props.profitCenterArea?.code ?? ""}`;
    }
    props.from = moment(props.from);
    props.to = moment(props.to);
       
    const promises = [];
    
    promises.push(fetch(`/api/sales?${qs}&periodType=${props.periodType}&from=${props.from.format("YYYYMMDD")}&to=${props.to.format("YYYYMMDD")}`)
      .then(response => response.json())
      .then((data) => {
        setStats(data.sales)
        setStatStatus({ ...statStatus, status: 'done'});
      }));

    if(props.compareFrom && props.compareTo){
      //console.log('useRangeStatistics: fetch compare');
      props.compareFrom = moment(props.compareFrom);
      props.compareTo = moment(props.compareTo);
      promises.push(fetch(`/api/sales?${qs}&periodType=${props.periodType}&from=${props.compareFrom.format("YYYYMMDD")}&to=${props.compareTo.format("YYYYMMDD")}`)
        .then(response => response.json())
        .then((data) => {
          setCompareStats(data.sales)
          setStatStatus({ ...statStatus, compareStatus: 'done'});
        }));
    }else {
      setCompareStats([]);
      setStatStatus({ ...statStatus, compareStatus: 'done'});
    }
    
    Promise.all(promises).then(()=>{
      setStatStatus({ status: 'done', compareStatus: 'done'});
    });

  }
  useEffect(() => {
    fetchStats();
  },[props.returnArray,props.from, props.to, props.compareFrom, props.compareTo,props.profitCenter, props.profitCenterArea]);
  
  if(props.returnArray){
    return [stats, compareStats, statStatus];
  }
  return { stats, compareStats, statStatus};
}
